import React, { useEffect, useState } from 'react'
import './style.scss'
import { Requests } from '../../../utils/Requests'
import LoadingComponent from '../../../components/loading/Index'
import { dateFormate } from '../../../utils/Helpers'
// import { useQuery } from 'react-query'


const Index = () => {
    const [isLoading, setLoading] = useState(true)
    // const [data, setData] = useState(null)
    const [userlist, setUserlist] = useState([])
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    useEffect(() => {

        const UserListData = async () => {
            const data = await Requests.UserList(header);
            setLoading(false)
            if (data) {
                setUserlist(data)
            }

        }
        UserListData();
    }, [header])
    
    return (
        <div className="user-container">
            {isLoading ? <LoadingComponent /> :
                <div className="container-fluid">
                    {/* User List  */}
                    <div className="row products-orders-status-container">
                        <div className="col-12 col-xl-12 col-padding">
                            <div className="card border-0 shadow-sm">
                                <div className="card-header">
                                    <h6>Your Create Users</h6>
                                </div>
                                <div className="card-body px-0 pt-2 pb-0 ">
                                    <table className="table table-sm table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <td className='text-center text-td' style={{ width: '5%' }}>SL</td>
                                                <td className='text-center text-td' style={{ width: '19%' }}>Date</td>
                                                <td className='text-center text-td' style={{ width: '19%' }}>ProfileId</td>
                                                <td className='text-center text-td' style={{ width: '19%' }}>Name</td>
                                                <td className='text-center text-td' style={{ width: '19%' }}>Phone</td>
                                                <td className='text-center text-td' style={{ width: '19%' }}>Email</td> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {userlist.map((item, i) => */}
                                            {userlist.map((item, i) =>
                                                <tr key={i}>
                                                    <td className='text-center' style={{ width: '5%' }}>{i + 1}</td>
                                                    <td className='text-center text-td' style={{ width: '19%' }}>{dateFormate(item.createdAt)}</td>
                                                    <td className='text-center ' style={{ width: '19%' }}>{item.profileId || ''}</td>
                                                    <td className='text-center' style={{ width: '19%' }}>{item.name || ''}</td>
                                                    <td className='text-center' style={{ width: '19%' }}>{item.phone || ''}</td>
                                                    <td className='text-center' style={{ width: '19%' }}>{item.email || ''}</td>
                                                </tr>

                                            )}
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            }
        </div>
    );
}

export default Index;
