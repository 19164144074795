import React, { useState } from 'react'
import Axios from 'axios'
import { api } from '../../../utils/api'
import Icon from 'react-icons-kit'
import { useForm } from 'react-hook-form'
import { x } from 'react-icons-kit/feather'
import Form from 'react-bootstrap/Form'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useHistory } from 'react-router-dom'

import './style.scss'
toast.configure({
    autoClose: 2000,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

const RegisterAccount = (props) => {
    const history = useHistory()
    const { handleSubmit, register, formState: { errors } } = useForm()
    const [gender, setGender] = useState({ value: null, gender: null, error: null })
    const [profileCreatedFor, setProfileCreatedFor] = useState({ value: null, error: null })
    const [isLoading, setLoading] = useState(false)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    
    const options = [
        { value: null, label: '--Select--', gender: null },
        { value: 'self', label: 'Self', gender: null },
        { value: 'son', label: 'Son', gender: 'male' },
        { value: 'daughter', label: 'Daughter', gender: 'female' },
        { value: 'brother', label: 'Brother', gender: 'male' },
        { value: 'sister', label: 'Sister', gender: 'female' },
        { value: 'friend', label: 'Friend', gender: null },
        { value: 'other', label: 'Other', gender: null },
    ]

    const handleOption = event => {
        const data = JSON.parse(event.target.value)
        setProfileCreatedFor({ value: data.value, error: null })

        if (data.gender) {
            setGender({ ...gender, value: data.gender, show: false, error: null })
        } else {
            setGender({ ...gender, value: null, show: true })
        }
    }

    const handleGender = event => setGender({ ...gender, value: event.target.value, error: null })

    const onSubmit = async data => {
        try {
            if (!profileCreatedFor.value) return setProfileCreatedFor({ value: null, error: 'This field is required.' })
            if (!gender.value) return setGender({ ...gender, error: 'Gender is required' })

            setLoading(true)
            const regData = {
                ...data,
                profileCreatedFor: profileCreatedFor.value,
                gender: gender.value
            }
            
            // const response = await Register(regData)
        // const response = await Requests.Register(regData, header)
        const response = await Axios.post(`${api}ghotok/createuser`, regData, header)
            if (response.status === 201){
                setLoading(false)
                toast.success(response.message)
                window.open(`${process.env.REACT_APP_BIYESADI_URL}?state=login`, "_blank")
                history.push('/dashboard')
                
            }
        } catch (error) {
            if (error) {
                setLoading(false)
                toast.error(error.response.data.message)
            }
        }
    }


    return (
        <div className="dashboard-container">
            {/* {isLoading ? <LoadingComponent /> : */}
            <div className="container-fluid">

                {/* statistics container */}
                <div className="row statistics-container">
                    <div className="col-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header border-0 bg-white p-4">
                                <div className="card-header bg-white border-0 px-2 py-3">
                                    <div className="text-right">
                                        <button type="button" className="btn btn-sm shadow-none">
                                            <Link
                                                to="/dashboard/package"
                                                type="button"
                                                className="btn shadow-none"
                                            >
                                                <Icon style={{ color: '#CF2969' }} icon={x} size={22} />
                                            </Link>
                                        </button>

                                        <div className="text-center px-3 pt-2">
                                            <h5>Let's set up your account, while we find Matches for you!</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-4 pb-4 px-lg-5 pb-lg-5" >
                                <div className="row">
                                    <div className="offset-md-3 col-12 col-md-6 form-group mb-4">
                                        <form onSubmit={handleSubmit(onSubmit)}>

                                            {/* E-mail */}
                                          

                                            <div className="form-group mb-4">
                                                {errors.name && errors.name.message ? (
                                                    <p className="text-danger">{errors.name && errors.name.message}</p>
                                                ) : <p>E-mail</p>
                                                }
                                                {/* <p>E-mail</p> */}
                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter your E-mail"
                                                    ref={register({
                                                        required: "email is required",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                />
                                            </div>

                                            {/* Phone */}
                                            <div className="form-group mb-3">
                                                {errors.number && errors.number.message ? (
                                                    <p className="text-danger">{errors.number && errors.number.message}</p>
                                                ) : <p>Phone</p>
                                                }

                                                <input
                                                    type="text"
                                                    name="phone"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter your Phone number"
                                                    ref={register({
                                                        required: "phone number is required",
                                                        pattern: {
                                                            value: /^(?:\+88|88)?(01[3-9]\d{8})$/,
                                                            message: "Invalid phone number"
                                                        }
                                                    })}

                                                />
                                            </div>

                                            {/* Password */}
                                            <div className="form-group mb-3">
                                                {errors.password && errors.password.message ? (
                                                    <p className="text-danger">{errors.password && errors.password.message}</p>
                                                ) : <p>Password</p>
                                                }

                                                <input
                                                    type="password"
                                                    name="password"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter password"
                                                    ref={register({
                                                        required: "password is required",
                                                        minLength: {
                                                            value: 8,
                                                            message: "Minimun length 8 character"
                                                        }
                                                    })}
                                                />
                                            </div>
                                            {/* </div> */}
                                            {/* Cretaed for */}
                                            <div className="form-group mb-3">
                                                <p>{profileCreatedFor.error ? <span className="text-danger">{profileCreatedFor.error}</span> : 'Created for'}</p>

                                                <select
                                                    className="form-control shadow-none pl-2"
                                                    onChange={handleOption}
                                                >
                                                    {options.map((item, i) => <option value={JSON.stringify(item)} key={i}>{item.label}</option>)}
                                                </select>
                                            </div>

                                            {/* Gender */}
                                            {gender.show ?
                                                <div className="form-group mb-3">
                                                    <div className="d-flex">
                                                        <div>{gender.error ? <p className="text-danger mb-0 mr-3">{gender.error}</p> : <p className="mb-0 mr-3">I am a </p>}</div>
                                                        <div>
                                                            <Form.Check
                                                                type="checkbox"
                                                                className="mb-2 mr-sm-2"
                                                                id="maleCheck"
                                                                label="Male"
                                                                value="male"
                                                                checked={gender.value === 'male'}
                                                                onChange={handleGender}
                                                            />
                                                        </div>

                                                        <div>
                                                            <Form.Check
                                                                type="checkbox"
                                                                className="mb-2 mr-sm-2"
                                                                id="femaleCheck"
                                                                label="Female"
                                                                value="female"
                                                                checked={gender.value === 'female'}
                                                                onChange={handleGender}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                            {/* <button type="submit" className="btn btn-block shadow-none" disabled={isLoading}>
                                                {isLoading ? <span>Loading...</span> : <span onClick={() => window.open('https://stackoverflow.com')} style={{backgroundColor:'#CF2969', padding:'8px 20px', color:'#fff'}}>Next</span>}
                                            </button> */}

                                            <button
                                                type="submit"
                                                className="btn btn-block shadow-none"
                                                disabled={isLoading}
                                                style={{ backgroundColor: '#CF2969', padding: '8px 20px', color: '#fff' }}
                                            >
                                                Next
                                            </button>


                                            {/* <div className="other-link text-center pt-4">
                                                <p>Already member ? <button className="btn  shadow-none" onClick={()=> window.open(`${process.env.REACT_APP_BIYESADI_URL}?state=login`, "_blank")} style={{ fontWeight:'bold', color:'#CF2969'}} >Login</button></p>
                                            </div> */}

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* }   */}
        </div >

    );
};

export default RegisterAccount;