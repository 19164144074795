import React from 'react'
import "./style.scss";
import Navbar from '../home/navber'
import Footer from '../home/footer'


const Index = () => {

    return (
        <div>
            
            <Navbar />
            <div div className="terms-container">
                <div className='header'>
                    Terms & Conditions
                    <hr />
                </div>
                <div className='intro'>
                    <h6>Introduction</h6>
                    <p>বিয়েশাদীকম এর নিয়োগপ্রাপ্ত এজেন্ট বিয়েশাদী.কম এর যাবতীয় শর্তাবলী মেনে কাজ করতে বাধ্য থাকবেন। বিয়েশাদী.কম কোম্পানির স্বার্থে যেকোনো সময় যেকোনো শর্ত পরিবর্ধন, সংশোধন বা বাতিল করতে পারে। </p>
                </div>
                <div className='terms'>
                    <h6>Terms and Condition</h6>
                    <ul className='parent'>
                        <li>১. বিয়েশাদী.কম এর এজেন্ট হওয়ার জন্য আপনাকে রেজিস্ট্রেশনের সময় অবশ্যই সঠিক জাতীয় পরিচয়পত্র/পাসপোর্ট নম্বর এবং ট্যাক্স আইডেনটিফিকেশন নম্বর (TIN Number) (যদি থাকে) দিতে হবে। মিথ্যা/অসম্পূর্ণ তথ্য প্রদান করলে আপনি আইনগতভাবে অপরাধী হিসাবে গণ্য হবেন। এছাড়া এজেন্ট রেজিস্ট্রেশনের সময় প্রদত্ত কোন ডকুমেন্ট সমূহে কোন ধরনের জালিয়াতি বা অবৈধতা প্রমাণিত হলে বিয়েশাদী.কম এধরনের একাউন্ট বন্ধ করে দেয়ার ক্ষমতা রাখে।  
                        </li>
                        <li>
                        ২. বিয়েশাদী.কম এর নিয়োগপ্রাপ্ত এজেন্টকে বিয়েশাদী.কম এর পক্ষ থেকে ’সিম’ দেয়া হবে। একজন এজেন্ট মার্কেটিংয়ের জন্য এবং ম্যাচমেকিং সার্ভিস দেয়ার জন্য উক্ত সিম ব্যবহার করবেন এবং গ্রাহকদের সাথে একই নম্বর থেকে যোগাযোগ করবেন। এছাড়া মার্কেটিংয়ের জন্য লিফলেট, ব্রুশিয়ার, ব্যানার ইত্যাদি কোম্পানি থেকেই দেয়া হবে। কোনো কারণে এজেন্টশীপ বাতিল হলে এজেন্ট কোম্পানির পক্ষ থেকে দেয়া ’সিম’, লিফলেট, ব্রুশিয়ার, ব্যানারসহ যাবতীয় উপকরণ ফেরত দিতে বাধ্য থাকবেন। 
                        </li>
                        <li>
                        ৩. বিয়েশাদী.কম এর সার্ভিস ব্যবহার করে জাতীয়, সামাজিক, রাজনৈতিক, ব্যক্তিগত অথবা অবৈধ কোন কর্মকান্ডের সাথে সংশ্লিষ্টতা প্রমাণিত হলে বিয়েশাদী.কম কোনোরকম পূর্ব নির্দেশনা ছাড়াই এজেন্ট একাউন্টটি সাময়িক/স্থায়ীভাবে বন্ধ করে দেয়ার ক্ষমতা রাখে। 
                        </li>
                        <li>
                        ৪. এজেন্ট যদি বিয়েশাদী.কম এর পরিচয় ব্যবহার করে কোনো অনৈতিক কর্মকান্ডে যুক্ত থাকেন অথবা যদি বিয়েশাদী.কম এর অনুমতি ছাড়া লোগো ব্যবহার করে ফেসবুক, ইউটিউব, হোয়াটসঅ্যাপ, ইমোসহ অন্যান্য স্যোশাল মিডিয়া সাইটে নিজস্ব কোনো সার্ভিস চালু করেন তাহলে বিয়েশাদী.কম ঐ এজেন্টের এজেন্টশীপ বাতিল করার এবং তার বিরুদ্ধে আইনগত ব্যবস্থা নেয়ার ক্ষমতা রাখে। 
                        </li>
                        <li>
                        ৫. বিয়েশাদী.কমের এজেন্ট থাকা অবস্থায় অন্য কোনো ম্যারেজ মিডিয়া বা ম্যাট্রিমনিয়াল এজেন্সীর হয়ে একই ধরনের কার্যক্রম পরিচালনা করলে বিয়েশাদী.কম উক্ত এজেন্টের এজেন্টশীপ বাতিল করে দেয়ার ক্ষমতা রাখে।  
                        </li>
                        <li>
                        ৬. বিয়েশাদী.কমে গ্রাহকের রেজিস্ট্রেশন করানোর আগে একজন এজেন্ট অবশ্যই গ্রাহকদের বিয়েশাদী.কম এর সার্ভিস সম্পর্কে বিস্তারিত জানাবেন। কোনোভাবে গ্রাহকের তথ্যের নিরাপত্তা বিনষ্ট করলে, গ্রাহককে অবাস্তব ও মিথ্যা আশ্বাস দিয়ে প্রতারণা করলে বিয়েশাদী.কম অভিযুক্ত এজেন্টের এজেন্টশীপ বাতিল করার ক্ষমতা রাখে। 
                        </li>
                        <li>
                        ৭. বিয়েশাদী.কমে গ্রাহকের রেজিস্ট্রেশন করানোর সময় এজেন্ট অবশ্যই পাত্র-পাত্রী ও তার অভিভাবকের মৌখিক সম্মতি নিয়ে রেজিষ্ট্রেশন করবেন। একজন এজেন্টের মাধ্যমে কোনো গ্রাহকের বিয়ে হলে এজেন্ট বিয়ে পরবর্তী কোনো সার্ভিস চার্জ বা বকশিস দাবি করতে পারবে না। বিয়ে পরবর্তী কোনো সার্ভিস চার্জ বা বকশিস দাবি করলে গ্রাহক উক্ত এজেন্টের বিরুদ্ধে বিয়েশাদী.কমে অভিযোগ করতে পারবে এবং অভিযোগ সত্য প্রমাণিত হলে বিয়েশাদী.কম এজেন্টের বিরুদ্ধে তাৎক্ষণিক ব্যবস্থা নিতে পারবে। 
                        </li>
                        <li>
                        ৮. কোনো এজেন্ট গ্রাহকদের ম্যাচমেকিং সার্ভিস দিতে ব্যর্থ হলে কিংবা কোনো কারণে কাজের অযোগ্য প্রমাণিত হলে বিয়েশাদী.কম তার এজেন্টশীপ বাতিল করতে পারবে। বিয়েশাদী.কম এর অনুমতি ছাড়া একজন এজেন্ট অন্য কোনো ব্যক্তি বা প্রতিষ্ঠানের কাছে তার এজেন্টশীপ হস্তান্তর করতে পারবে না। আর একজন এজেন্ট স্বেচ্ছায় তার এজেন্টশীপ বাতিল করতে চাইলে বিয়েশাদী.কম- কে কমপক্ষে ৩০ দিন আগে জানাতে হবে। 
                        </li>
                        <li>
                        ৯. কোন ব্যক্তি বা প্রতিষ্ঠান যদি বিয়েশাদী.কম এর লেখা কন্টেন্ট, ছবি, অডিও বা ভিডিও, হুবহু বা আংশিক নকল করে অনলাইনে বা অফলাইনে ব্যবসায়িক কার্যপরিচালনা করে তাহলে বিয়েশাদী.কম ঐ ব্যক্তি বা প্রতিষ্ঠানের বিরুদ্ধে আইনগত ব্যবস্থা নেয়ার অধিকার রাখে।
                        </li>
                        
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
};



export default Index;