import React, { useState } from 'react'
import './style.scss'
import Axios from 'axios'
import { api } from '../../utils/api'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { Images } from '../../utils/Images'
import 'react-toastify/dist/ReactToastify.css'
import { Link, useHistory } from 'react-router-dom'
// import ProfileEditModal from '../../components/modal/Profile'
// import OptionModel from '../../components/modal/resetPassword/OptionModel'
import OptionModel from '../../components/modal/resetPassword/OptionModel'
// import ProfileEditModal from '../../../components/modal/Profile'

// import Otp from '../../components/modal/resetPassword/otp'
// import Frogot from '../../components/modal/resetPassword/forgot'
// import ForgotOption from '../auth/option'
toast.configure({ autoClose: 2000 })
const Login = () => {
    const history = useHistory()
    const [show, setShow] = useState(false)
    const { register, handleSubmit, errors } = useForm()
    const [isLogging, setLogging] = useState(false)


    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLogging(true)
            const response = await Axios.post(`${api}ghotok/login`, data)
            if (response.status === 200) {
                setLogging(false)
                localStorage.setItem('token', response.data.token)
                if (response.data.verified) {
                    history.push('/dashboard')
                }
                else {
                    history.push('/register/phone-verified');
                }

            }
        } catch (error) {
            if (error) {
                setLogging(false)
                toast.error(error.response ? error.response.data.message : null)
            }
        }
    }

    return (
        <div className="auth">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 d-none d-lg-block p-0">
                        <div className="image-container">
                            <div className="overlay">
                                <div className="flex-center flex-column">
                                    <img src={Images.Agent} style={{ width: "35%", heigh: 50 }} className="img-fluid" alt="..." />
                                    {/* <h2>Agent </h2> */}
                                    {/* <p className="mb-0">Ghotok Media</p> */}
                                    <p>Create Agent account & start your own buisness with us!!!</p>
                                    <Link
                                        to="/register"
                                        type="button"
                                        className="btn shadow-none"
                                    >Registration</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 py-3 credential-container">
                        <div className="flex-center flex-column">
                            <div className="card border-0">
                                <div className="text-center text-lg-left">
                                    <div className="d-lg-none">
                                        <img src={Images.Agent} className="img-fluid" alt="..." />
                                    </div>
                                    <h3 className="mb-0 mb-lg-4" style={{ marginTop: "10px" }}>Login Account</h3>
                                    <p className="d-lg-none mb-4">Create Agent account & start your own buisness with us!!!</p>
                                </div>

                                <form onSubmit={handleSubmit(onSubmit)}>

                                    {/* E-mail */}
                                    <div className="form-group mb-4">
                                        {errors.email && errors.email.message ? (
                                            <p className="text-danger">{errors.email && errors.email.message}</p>
                                        ) : <p>E-mail</p>
                                        }

                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none"
                                            placeholder="Enter e-mail"
                                            ref={register({
                                                required: "E-mail is required",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                        />
                                    </div>

                                    {/* Password */}
                                    <div className="form-group mb-4">
                                        {errors.password && errors.password.message ? (
                                            <p className="text-danger">{errors.password && errors.password.message}</p>
                                        ) : <p>Password</p>
                                        }

                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control shadow-none"
                                            placeholder="Enter password"
                                            ref={register({
                                                required: "Please enter password",
                                                minLength: {
                                                    value: 8,
                                                    message: "Minimun length 8 character"
                                                }
                                            })}
                                        />
                                    </div>


                                    <div className="d-flex">
                                        <div>
                                            {/* <div type="button" onClick={() => setShow(true)}>
                                                Forgot password ?
                                            </div> */}
                                            <Link to="/reset">Forgot password ?</Link>
                                            <br />
                                            <Link to="/register" className="d-lg-none">Have no account ? Register</Link>
                                        </div>
                                        <div className="ml-auto">
                                            <button type="submit" className="btn shadow-none" disabled={isLogging}>
                                                {isLogging ? <span>Logging in...</span> : <span>Login</span>}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ghotok &&  */}
            {show ?
                <OptionModel
                    show={show}
                    // data={ghotok}
                    // loading={isUpdate}
                    // update={handleUpdate}
                    onHide={() => setShow(false)}
                />
                : null}
        </div>
    );
}

export default Login;