import React from "react";
import "./style.scss";
import { useHistory } from 'react-router-dom'

const Footer = () => {
    const history = useHistory();
    return (
        <div>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 footerLeftBackground">
                            <h4>CONTACT US</h4>
                            <h5>+880 1317-801712</h5>
                            <h5 type="button" onClick={() => history.push(`/termsandconditions`)}>Terms & Conditions</h5>
                        </div>
                        <div className="col-sm-6 footerRightBackground footer-space">
                            <h4>ADDRESS</h4>
                            <h5>AB Super Market, Suite-404/A, Level-3,</h5>
                            <h5>Sector-3, Uttara, Dhaka-1230</h5>
                        </div>
                    </div>

                </div>
            </div>
            <div className="footerCopy">
                <div className="container">
                    <div className="row ">
                        <div className="col-sm-8">
                            <p><small><small><span className="numberCircle">C</span></small></small>2021-2022 biyeshadi.com, The
                                World's No.1 Matchmaking Service<sup>TM</sup></p>
                        </div>
                        <div className="col-sm-4">
                            <p className="text-center">Passionately created by <a
                                className="color-colourbangla" href="https://www.colourbangla.com/"
                                target="_blank" rel="noreferrer">colourbangla.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;