import React from "react"
import Icon from "react-icons-kit"
import { smartphone, mail, x } from "react-icons-kit/feather"
import { Link } from 'react-router-dom'
import './style.scss'

const ForgotOption = (props) => {
    return (
        <div className="passwordCard">
            <div className="card-header bg-white border-0 px-2 py-3">
                <div className="text-right">


                    <div className="text-center pt-2">
                        <h5>Choose your verification option
                        <Link
                                    to="/login"
                                    type="button"
                                    className="btn shadow-none"
                                >
                                    <Icon style={{  marginTop: '-12px' }} icon={x} size={20} />
                                </Link>
                            {/* <button type="button" className="btn btn-sm shadow-none" onClick={props.onHide}>
                                <Icon icon={x} size={22} />
                            </button> */}
                        </h5>
                    </div>
                </div>
            </div>
            <div className="card-body d-flex justify-content-center p-4">
                <button
                    className="btn mx-5 shadow-none"
                // onClick={() => props.setOption('email')}
                >
                    <Link
                        to="/reset-email"
                    >
                        <Icon style={{color:"#000"}} icon={mail} size={60} />
                        <span style={{color:"#000"}}>Email</span>
                    </Link>

                </button>

                <button
                    className="btn mx-5 shadow-none"
                    // onClick={() => props.setOption('phone')}
                >
                    <Link
                        to="/reset-phone"
                    >
                        <Icon style={{color:"#000"}} icon={smartphone} size={60} />
                        <span style={{color:"#000"}}>Phone</span>
                    </Link>
                    {/* <Icon icon={smartphone} size={60} />
                    <span>Phone</span> */}
                </button>
            </div>
        </div>
    );
};

export default ForgotOption;