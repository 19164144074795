import React, {useState, useEffect} from "react";
import "./style.scss";
import { Images } from "../../utils/Images";
import { useHistory } from "react-router-dom";
import Footer from "./footer"
import Navber from "./navber"
const Home = () => {
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    const history = useHistory();
    const [selected, setSelected,] = useState(null)
    const toggle = (i) =>{
       if(selected === i){
           return setSelected(null)
        }
        setSelected(i)
    }
    useEffect(() => {
        // localStorage.clear()
        if(localStorage.getItem('token')){
            return history.push('/dashboard/')
        }

    }, [history, header])

    return (
        <div className="homeMain">
            <Navber/>
            <div className="cover-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <button onClick={() => history.push(`/login`)}>Join Now</button>
                        </div>
                        {/* </div> */}
                        <div className="col align-self-end">
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                {/* <!-- Start 2nd Section Agent --> */}
                <div className="Agent">
                    <h1 className="blue-color emtry">BiyaSadi Agent</h1>
                    <div className="container">
                        <div className="row ">
                            <div className="col-sm-1 ">

                            </div>
                            <div className="col-sm box">
                                <div className="text-center">
                                    <div className="rounded-background">
                                        <img src={Images.MaskGroup4} alt="..." />
                                    </div>
                                    <h5 className="font-weight-bold text-center">Best Matches </h5>
                                </div>
                            </div>
                            <div className="col-sm box">
                                <div className="text-center">
                                    <div className="rounded-background">
                                        <img src={Images.MaskGroup5} alt="..." />
                                    </div>
                                    <h5 className="font-weight-bold text-center">Verified Profiles</h5>
                                </div>
                            </div>
                            <div className="col-sm box">
                                <div className="text-center">
                                    <div className="rounded-background">
                                        <img src={Images.MaskGroup1} alt="Delivery Icon" />
                                    </div>
                                    <h5 className="font-weight-bold text-center">Upto 50% Profit</h5>
                                </div>
                            </div>
                            <div className="col-sm-1 ">

                            </div>
                        </div>
                        <div className="row emtry">
                            <div className="col-sm-1 ">

                            </div>
                            <div className="col-sm box">
                                <div className="text-center">
                                    <div className="rounded-background">
                                        <img src={Images.hundred} alt="..." />
                                    </div>
                                    <h5 className="font-weight-bold text-center">Free Training</h5>
                                </div>
                            </div>
                            <div className="col-sm box">
                                <div className="text-center">
                                    <div className="rounded-background">
                                        <img src={Images.material} alt="..." />
                                    </div>
                                    <h5 className="font-weight-bold text-center">Free Comsultation</h5>
                                </div>
                            </div>
                            <div className="col-sm box">
                                <div className="text-center">
                                    <div className="rounded-background">
                                        <img src={Images.materialMoney} alt="..." />
                                    </div>
                                    <h5 className="font-weight-bold text-center">No Investment Need</h5>
                                </div>
                            </div>
                            <div className="col-sm-1 ">

                            </div>
                        </div>
                        <div className="text-center">
                            <button type="button" 
                                className="btn  btn-color " onClick={() => history.push(`/register`)}>SIGN UP NOW</button>
                        </div>
                    </div>
                </div>
                {/* <!-- End 2nd Section Agent --> */}

                <div className="about">
                    <div className="text-center">
                        <h2 className="blue-color mt-5">About Us</h2>
                    </div>
                    <div className="text-justify">
                        <p className="emtry">বিয়েশাদী.কম দেশের সর্বপ্রথম পূর্ণাঙ্গ ম্যাচমেকিং সাইট বা পাত্র-পাত্রী খুঁজে দেয়ার জন্য
                            সবচেয়ে বড় ওয়েবসাইট। দেশের প্রতিটি প্রতিটি বিভাগ, জেলা, উপজেলা ও থানা পর্যায়ের মানুষ যাতে সহজেই
                            অনলাইনে পাত্র-পাত্রী খুঁজে নিতে পারে এবং দেশের বাইরে প্রবাসী বাংলাদেশীদের কাছে ম্যাচমেকিং সার্ভিসকে
                            পৌঁছে দিতে আমরা চালু করেছি বিয়েশাদী এজেন্ট সার্ভিস প্রোগ্রাম। <br />
                            যেসব তরুণ-তরুণীরা ব্যবসায়ের জন্য নতুন উদ্যোগের কথা চিন্তা করছেন তাদের জন্য এটি একটি সুবর্ণ ‍সুযোগ।
                            এজেন্ট প্রোগ্রামের মাধ্যমে বেকার তরুণ-তরুণীরা নিজেদের কর্মসংস্থান নিশ্চিত করতে পারবেন। আমাদের লক্ষ্য
                            দেশের প্রতিটি থানা পর্যায়ে বিয়েশাদী’র ন্যূনতম ১টি ব্রাঞ্চ অফিস স্থাপন করা। বিয়েশাদী’র সার্ভিস
                            বিক্রয়ের মাধ্যমে একজন এজেন্ট কোম্পানির নিয়ম অনুযায়ী আকর্ষণীয় কমিশন পাবেন। বিয়েশাদী’র সার্ভিস
                            বিক্রয়ের মাধ্যমে আপনাকে একজন সফল উদ্যোক্তা হিসেবে গড়ে তোলাই আমাদের লক্ষ্য।</p>
                    </div>
                    {/* </div> */}
                </div>
                {/* <!-- Start 3rd Section Video --> */}
                <div className="video-section text-center">
                    <div className="container">
                        <div className="d-flex justify-content-center">
                            <div className="p-2 flex-fill bd-highlight">
                                <iframe className="video-controller shadow-sm rounded" title="biyasadiid"
                                    src="https://www.youtube.com/embed/3FObaED46wk"
                                />
                                {/* </iframe> */}
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!-- End 3rd Section Video  --> */}

                <div className="container">
                    <div className="container">
                        <div className="container">
                            <div className="testimonial">
                                <h1 className="blue-color emtry">Trusted by millions worldwide</h1>

                                <div className="row backgroundImage">
                                    <div className="col-sm-3 align-self-center">
                                        <img src={Images.TraUser} alt="..." />
                                    </div>
                                    <div className="col-sm-8 align-self-center">
                                        <p>We would like to thank biyasadi for helping us to find the right match for our
                                            daughter. We are happy with things worked out and we would recommend them to anyone
                                            looking for a service.</p>
                                        <h5>-Alim</h5>
                                    </div>
                                </div>
                                <div className="text-center"> <button className="btn  btn-write"  onClick={() => history.push(`/register`)}>Write for us</button></div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="accordion">
                        {data.map((item, index) =>(
                            <div className="item " key={index}>
                                <div className="title" onClick={()=>toggle(index)}>
                                    <h5>{item.title}</h5>
                                    <span>{selected === index? '-':'+'}</span>
                                </div>
                                <div className={selected === index? 'content show':'content'}>{item.content}</div>
                            </div>
                        ))}    
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};
const data = [
    {
        title : "বিয়েশাদী’র এজেন্ট প্রোগ্রাম কি?",
        content: "অনলাইনে পাত্র-পাত্রী খুঁজে দেয়ার সার্ভিসকে দেশের প্রতিটি বিভাগ, জেলা, উপজেলা ও থানা পর্যায়ে পোঁছে দিতে বিয়েশাদী চালু করেছে এজেন্ট প্রোগ্রাম। এই প্রোগ্রামের মাধ্যমে দেশের বাইরে থাকা প্রবাসী বাংলাদেশীরাও খুব সহজে পাত্র-পাত্রী খুঁজে নিতে পারবেন। বিয়েশাদী এজেন্ট প্রোগ্রামে আমরা বাংলাদেশের প্রত্যেকটি বিভাগ, জেলা ও উপজেলা সদরে পাত্র-পাত্রী খুঁজে দেয়ার জন্য এজেন্ট নিয়োগ করবো।",
    },
    {
        title : "এজেন্টের মূল কাজ কি?",
        content: "বিবাহযোগ্য পাত্র-পাত্রীরা হচ্ছে বিয়েশাদী’র মূল গ্রাহক। এজেন্টদের কাজ হবে নিজেদের জেলা বা উপজেলায় বিবাহযোগ্য পাত্র-পাত্রীদের কাছে বিয়েশাদী’র সার্ভিস বিক্রয় করা। এজেন্টরা নিজেদের এলাকায় বিবাহযোগ্য, সুশিক্ষিত ও প্রতিষ্ঠিত পাত্র-পাত্রীদের ছবি ও বায়োডাটা দিয়ে বিয়েশাদী’র ওয়েবসাইটে তাদেরকে একাউন্ট ওপেন করে দিবেন। একাউন্ট ওপেন করার পর এজেন্টের কাজ হবে উক্ত গ্রাহকের কাছে বিয়েশাদী’র প্যাকেজ বিক্রয় করা। বিয়েশাদী’র ওয়েবসাইটে প্রত্যেক এজেন্টের নিজস্ব একটি ওয়েব প্যানেল থাকবে। এজেন্ট তার ওয়েব প্যানেলে লগিন করে কতজন গ্রাহকের একাউন্ট ওপেন করেছেন এবং তার বিক্রয়ের জন্য প্রাপ্ত কমিশনসহ যাবতীয় তথ্য দেখতে পারবেন।",
    },
    {
        title : "কেন আমি বিয়েশাদী’র এজেন্ট হবো?",
        content: "বিয়েশাদী’র সার্ভিস বিক্রয়ের মাধ্যমে আপনি প্রতিমাসে আকর্ষণীয় কমিশন পাবেন। একজন এজেন্ট হিসেবে আপনি প্রতিমাসে ১০,০০০ টাকা থেকে শুরু ১,০০,০০০ টাকা পর্যন্ত বা তারও বেশি টাকা ইনকাম করতে পারবেন। আপনি যত বেশি প্যাকেজ বিক্রয় করতে পারবেন, কমিশন তত বেড়ে যাবে। এছাড়া প্রতিমাসে এজেন্টের পারফরমেন্স বিবেচনা করে কোম্পানির পক্ষ থেকে আকর্ষণীয় সুযোগ-সুবিধা দেওয়া হবে। একজন এজেন্ট তার নিজের এলাকায় ম্যাচমেকিং সার্ভিস দেয়ার জন্য পূর্ণ স্বাধীনতা পাবেন।",
    },
    {
        title : "কিভাবে আমি বিয়েশাদী’র এজেন্ট হতে পারি?",
        content: "আপনি যদি ম্যাচমেকিং বা পাত্র-পাত্রী খুঁজে দেয়ার সার্ভিস নিয়ে কাজ করতে আগ্রহী থাকেন তাহলে আমাদের এজেন্ট হওয়ার জন্য রেজিস্ট্রেশন করতে পারেন। বিয়েশাদী’র এজেন্ট হওয়ার জন্য প্রথমে আপনাকে অনলাইনে “রেজিস্ট্রেশন ফর্ম” এর প্রয়োজনীয় তথ্যগুলো পূরণ করে সাবমিট করতে হবে। এরপর বিয়েশাদী টিম আপনার দেয়া সমস্ত তথ্য ভেরিফেকেশন করে যোগ্যতার ভিত্তিতে আপনাকে বিয়েশাদী’র এজেন্ট হিসেবে নিয়োগ দিবেন।",
    },
    {
        title : "বিয়েশাদী’র এজেন্টশীপ নিতে হলে কত টাকা জামানত  লাগবে?",
        content: "বিয়েশাদী’র এজেন্টশীপ নিতে কোনো ফি বা জামানত লাগবে না। আপনি সম্পূর্ণ বিনামূল্য আমাদের এজেন্ট হওয়ার জন্য আবেদন করতে পারবেন।",
    },
    {
        title : "এজেন্টশীপ নিতে হলে আমার কি যোগ্যতা লাগবে?",
        content: "এজেন্ট নিয়োগের জন্য ন্যূনতম এইচএসসি পাশ এবং যারা মাইক্রোসফট অফিস ও ইন্টারনেট ব্রাউজিংয়ে এ পারদর্শী তাদেরকে অগ্রাধীকার দেওয়া হবে। তবে আপনি যদি ম্যাচমেকিং বা পাত্র-পাত্রী খুঁজে দেয়ার সার্ভিস নিয়ে কাজ করতে বিশেষভাবে আগ্রহী থাকেন তাহলে আপনিও সুযোগ পাবেন।",
    },
    {
        title : "রেজিস্ট্রেশনের সময় আমার কি কি ডকুমেন্টস/পেপার লাগবে?",
        content: "বিয়েশাদী‘র এজেন্ট হওয়ার জন্য রেজিস্ট্রেশনের সময় আপনার দুই কপি পাসপোর্ট সাইজের রঙ্গীন ছবি, শিক্ষাগত যোগ্যতার সনদ, NID/জাতীয় পরিচয়পত্রের স্ক্যান কপির প্রয়োজন হবে।",
    },
    {
        title : "এজেন্টশীপ নেয়ার পর আমাকে কি ট্রেনিং দেয়া হবে?",
        content: "রেজিস্ট্রেশনের পর ভেরিফিকেশন সম্পন্ন হলে চূড়ান্তভাবে নির্বাচিত এজেন্টদেরকে অনলাইন অথবা অফলাইনে ন্যূনতম ৩ দিনের একটি ট্রেনিং দেয়া হবে। ট্রেনিংয়ে বিয়েশাদী’র সার্ভিস, এজেন্ট প্রোগ্রামের আওতায় কি কাজ করতে হবে, কিভাবে সার্ভিস দিতে হবে এবং ব্যবসায়ের যাবতীয় শর্তাবলী বিস্তারিত জানিয়ে দেয়া হবে।",
    },
    {
        title : "একজন এজেন্ট সার্ভিস টিম থেকে কি কি ধরনের সুবিধা পাবেন?",
        content: "ট্রেনিং শেষে এজেন্টরা নিজেদের এলাকায় স্বাধীনভাবে নিজেদের কার্যক্রম পরিচালনা করবেন। এজেন্টদেরকে মার্কেটিংয়ের জন্য প্রয়োজনীয় সব উপকরণ যেমন- লিফলেট, ব্রুশিয়ার, ব্যানার ইত্যাদি কোম্পানি থেকেই প্রদান করা হবে।",
    },
    {
        title : "একজন এজেন্ট এর কি কোন টার্গেট থাকে?",
        content: "কোম্পানির নীতিমালা অনুযায়ী একজন এজেন্টকে দৈনিক/সাপ্তাহিক/মাসিক টার্গেট দেয়া হবে। চূড়ান্ত নিয়োগের সময় এজেন্টকে তার টার্গেট জানিয়ে দেয়া হবে।",
    },
    {
        title : "এজেন্ট সাপোর্টের জন্য কোন নম্বরে যোগাযোগ করতে হবে?",
        content: "আমাদের Contact Us সেকশনে দেয়া নম্বর অথবা ইমেইলের মাধমে যোগাযোগ করে আপনি যাবতীয় সার্ভিস নিতে পারবেন।",
    },
]
export default Home;
