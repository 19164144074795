import React, { useState } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import Modal from 'react-bootstrap/Modal'
import { ic_clear } from 'react-icons-kit/md'
import { useForm } from 'react-hook-form'

const Profile = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const [mobileNumberFor, setMobileNumberFor] = useState('');
    
    // Submit Form
    const onSubmit = data => props.update(data, mobileNumberFor)

    return (
        <div>
            <Modal
                show={props.show}
                dialogClassName="custom-profile-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mb-0 mt-2">Edit Info.</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            {/* whatsapp Or Imo */}
                            <div className="form-group mb-4 col-md-6">
                                {errors.whatsappOrImo && errors.whatsappOrImo.message ? (
                                    <p className="text-danger">{errors.whatsappOrImo && errors.whatsappOrImo.message}</p>
                                ) : <p>Whatsapp Or Imo</p>
                                }

                                <input
                                    type="text"
                                    name="whatsappOrImo"
                                    className="form-control shadow-none"
                                    placeholder="Enter whatsapp or imo number"
                                    defaultValue={props.data && props.data.whatsappOrImo ? props.data.whatsappOrImo : null}
                                    ref={register({ required: "Whatsapp Or Imo is required" })}
                                />
                            </div>

                            {/* Phone */}
                            <div className="form-group mb-4 col-md-6">
                                {errors.phone && errors.phone.message ? (
                                    <p className="text-danger">{errors.phone && errors.operatorNumber.message}</p>
                                ) : <p>Phone</p>
                                }

                                <input
                                    type="text"
                                    name="phone"
                                    className="form-control shadow-none"
                                    placeholder="Enter Phone number"
                                    defaultValue={props.data && props.data.phone ? props.data.phone : null}
                                    ref={register({ required: "phone number is required" })}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            {/* mobile Banking Number */}
                            <div className="form-group mb-4 col-md-6">
                                {errors.mobileBankingNumber && errors.mobileBankingNumber.message ? (
                                    <p className="text-danger">{errors.mobileBankingNumber && errors.mobileBankingNumber.message}</p>
                                ) : <p>mobile Banking Number</p>}

                                <input
                                    type="text"
                                    name="mobileBankingNumber"
                                    className="form-control shadow-none"
                                    placeholder="Enter mobile Banking Number"
                                    defaultValue={props.data && props.data.mobileBankingNumber ? props.data.mobileBankingNumber : null}
                                    ref={register({ required: "mobileBankingNumber required." })}

                                />
                            </div>

                            <>

                                <br />
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input"
                                        type="radio"
                                        name="mobileNumberFor"
                                        id="inlineRadio1"
                                        onChange={() => setMobileNumberFor("Bkash")} value="Bkash"
                                        // defaultValue={props.data && props.data.mobileBankingNumber ? props.data.mobileBankingNumber : null}
                                        checked={props.data && props.data.mobileNumberFor === "Bkash" ? props.data.mobileNumberFor : null}
                                        ref={register({ required: "mobileNumberFor number is required" })}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Bkash</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input"
                                        type="radio" name="mobileNumberFor"
                                        id="inlineRadio2" value="Nagad"
                                        checked={props.data && props.data.mobileNumberFor === "Nagad" ? props.data.mobileNumberFor : null}
                                        onChange={() => setMobileNumberFor("Nagad")}
                                        ref={register({ required: "mobileNumberFor number is required" })} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">Nagad</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input"
                                        type="radio" name="mobileNumberFor" id="inlineRadio3"
                                        value="Rocket"
                                        checked={props.data && props.data.mobileNumberFor === "Rocket" ? props.data.mobileNumberFor : null}
                                        onChange={() => setMobileNumberFor("Rocket")} 
                                        ref={register({ required: "mobileNumberFor number is required" })}/>
                                    <label className="form-check-label" htmlFor="inlineRadio3">Rocket</label>
                                </div>
                            </>
                        </div>
                        <div className="form-row">
                        {/* Facebook */}
                        <div className="form-group mb-4 col-md-6">
                                {errors.fbPageUrl && errors.fbPageUrl.message ? (
                                    <p className="text-danger">{errors.fbPageUrl && errors.fbPageUrl.message}</p>
                                ) : <p>Fb Page</p>}
                                <input
                                    type="text"
                                    name="fbPageUrl"
                                    className="form-control shadow-none"
                                    placeholder="Enter Facebook Page"
                                    defaultValue={props.data && props.data.fbPageUrl ? props.data.fbPageUrl : null}
                                    ref={register({ required: "fbPageUrl name required." })}

                                />
                            </div>  
                            {/* Reference Way */}
                            <div className="form-group mb-4 col-md-6">
                                {errors.referenceWay && errors.referenceWay.message ? (
                                    <p className="text-danger">{errors.referenceWay && errors.referenceWay.message}</p>
                                ) : <p>Reference Way</p>}
                                <input
                                    type="text"
                                    name="referenceWay"
                                    className="form-control shadow-none"
                                    placeholder="Enter Reference Way"
                                    defaultValue={props.data && props.data.referenceWay ? props.data.referenceWay : null}
                                    ref={register({ required: "referenceWay name required." })}

                                />
                            </div>                            
                        </div>                       
                        <div className="text-right">
                            <button type="submit" className="btn shadow-none" disabled={props.loading}>{props.loading ? 'Updating...' : 'Update'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Profile;