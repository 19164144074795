import React, { useEffect } from 'react'
import './style.scss'
// import { Images } from '../../utils/Images'
import 'react-toastify/dist/ReactToastify.css'
import { Switch, Route } from 'react-router-dom'
import {  useHistory } from 'react-router-dom'

import StepOnePage from './Register/StepOne'
// import StepTwoPage from './Register/StepTwo'
import StepThreePage from './Register/StepThree'
// import Reset from "../auth/Reset"
import Reset from "../auth/option/option"
import Navber from "../home/navber"
const Register = () => {
    const history = useHistory()
    const token = localStorage.getItem('token')

    useEffect(() => {
        // if (token) history.push('/dashboard')
        if (token) history.push('/register/phone-verified')
    }, [history, token])


    return (
        <div className="auth">
            <Navber/>
            <div className="container">
                <div className="row" >
                    <div className="col-12 col-lg-12 py-3 credential-container">
                        <div className="flex-center flex-column">
                            <div className="card border-0">
                                <div className="text-center text-lg-left">
                                    <div className="d-lg-none">
                                        {/* <img src={Images.Logo} className="img-fluid" alt="..." /> */}
                                    </div>
                                    <h3 className="mb-0 mb-lg-4" style={{marginTop: "125px"}}>Get Started!</h3>
                                    <p className="d-lg-none mb-4" style={{marginTop: "10px"}}>Create Agent account & start your own buisness with us!!!</p>
                                </div>
                                <Switch>
                                    <Route exact path="/register/" component={StepOnePage} />
                                    <Route  path="/reset" component={Reset} />
                                    <Route exact path="/register/phone-verified" component={StepThreePage} />
                                </Switch>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;