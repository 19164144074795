import React, { useState, useEffect } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { NavLink } from 'react-router-dom'
import { grid, plusCircle, fileText, chevronRight,users } from 'react-icons-kit/feather'
import { Requests } from '../../utils/Requests'
import Navbar from '../navbar/Index'

const Layout = ({ ghotok }) => {
    //  const history = useHistory();
    const [show, setShow] = useState(false)
    // const [agentStatus, setAgentStatus] = useState({})
    const [view, setView] = useState(true)
    const [isMenu, setMenu] = useState(false)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    useEffect(() => {

        const AgentStatusData = async () => {
            const data = await Requests.AgentStatus(header);
            if (data.status === "Approved") {
                setView(false)
            }
            
           

        }
        AgentStatusData();
    }, [header])
    // Toggle menu
    const toggleMenu = event => {
        let current = event.target.getAttribute("data-value")

        if (isMenu === current) {
            setMenu(false)
        } else {
            setMenu(current)
        }
    }



    return (
        <div className="layout">

            {/* Navbar */}
            <div className="navbar-container shadow-sm">
                <Navbar ghotok={ghotok} toggle={() => setShow(!show)} />
            </div>

            {/* Sidebar */}
            <div className="sidebar-container">
                {/* Backdrop */}
                <div className={show ? "backdrop d-lg-none open-backdrop" : "backdrop d-lg-none"} onClick={() => setShow(false)}></div>

                <div className={show ? "sidebar shadow open-sidebar" : "sidebar shadow"}>
                {view?(
                    <ul>
                    <li>
                        <NavLink
                            exact
                            to="/dashboard/"
                            activeClassName="isActive"
                            type="button"
                            className="btn shadow-none"
                        ><Icon icon={grid} size={16} className="icon" />Dashboard</NavLink>
                    </li>
                    
                    <li>
                        <NavLink
                            exact
                            
                            to="/dashboard/status-change/"
                            type="button"
                            className="btn shadow-none"
                        ><Icon icon={plusCircle} size={18} className="icon" />Package</NavLink>
                    </li>

                    {/* Finance */}
                    <li>
                        <div className="sidebar-dropdown-container">
                            <button
                                type="button"
                                className="btn shadow-none"
                                onClick={toggleMenu}
                                data-value="finance"
                            >
                                <Icon icon={fileText} size={18} className="icon" />Finance
                                <Icon icon={chevronRight} size={18} className={isMenu === 'finance' ? "arrow down" : "arrow"} />
                            </button>

                            <div className={isMenu === 'finance' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                <NavLink
                                    exact
                                    to="/dashboard/status-change/"
                                    
                                    type="button"
                                    className="btn shadow-none"
                                >Wallet</NavLink>
                            </div>
                        </div>
                    </li>
                    <li>
                        <NavLink
                            exact
                            to="/dashboard/status-change/"
                            type="button"
                            className="btn shadow-none"
                        ><Icon icon={users} size={16} className="icon" />User</NavLink>
                    </li>
                </ul>
                ):(
                    <ul>
                    <li>
                        <NavLink
                            exact
                            to="/dashboard/"
                            activeClassName="isActive"
                            type="button"
                            className="btn shadow-none"
                        ><Icon icon={grid} size={16} className="icon" />Dashboard</NavLink>
                    </li>
                    
                    <li>
                        <NavLink
                            exact
                            to="/dashboard/package/"
                            activeClassName="isActive"
                            type="button"
                            className="btn shadow-none"
                        ><Icon icon={plusCircle} size={18} className="icon" />Package</NavLink>
                    </li>

                    {/* Finance */}
                    <li>
                        <div className="sidebar-dropdown-container">
                            <button
                                type="button"
                                className="btn shadow-none"
                                onClick={toggleMenu}
                                data-value="finance"
                            >
                                <Icon icon={fileText} size={18} className="icon" />Finance
                                <Icon icon={chevronRight} size={18} className={isMenu === 'finance' ? "arrow down" : "arrow"} />
                            </button>

                            <div className={isMenu === 'finance' ? "sidebar-dropdown-menu" : "sidebar-dropdown-menu menu-hide"}>
                                <NavLink
                                    exact
                                    to="/dashboard/wallet"
                                    activeClassName="isActive"
                                    type="button"
                                    className="btn shadow-none"
                                >Wallet</NavLink>
                            </div>
                        </div>
                    </li>
                    <li>
                        <NavLink
                            exact
                            to="/dashboard/user/"
                            activeClassName="isActive"
                            type="button"
                            className="btn shadow-none"
                        ><Icon icon={users} size={16} className="icon" />User</NavLink>
                    </li>
                </ul>
                )} 
                    
                </div>
            </div>
        </div>
    );
}

export default Layout;