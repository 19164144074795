import React, { useState } from "react"
// import Icon from "react-icons-kit"
import { useForm } from "react-hook-form"
// import { x } from "react-icons-kit/feather"
import { ResetPassword } from "../../../utils/Password"
import { toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {  useHistory,useParams } from 'react-router-dom'
import './style.scss'
toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

const Forgot = (props) => {
    const params = useParams()
    const history = useHistory()
    const { handleSubmit, register, setError, formState: { errors } } = useForm()
    const [isLoading, setLoading] = useState(false)

    const onSubmit = async data => {
        try {
            setLoading(true)
            if(data.newPassword !== data.confirmNewPassword){
                setError("confirmNewPassword", {
                    type: "manual",
                    message: "confirm password didn't match",
                });
                setLoading(false)
            }
        else{
            
            let obj = {
                newPassword:data.newPassword,
                email: params.email
            }
           
            const response = await ResetPassword(obj)
            if (response.status === 201) {
                history.push("/login") 
                setLoading(false)
                toast.success(response.data.message)
            }
        } 
        }
        catch (error) {
            if (error) {
                setLoading(false)
               
            }
        }
    }

    return (
        <div  className="passwordCard">
            <div className="card-header bg-white border-0 px-2 py-3">
                <div className="text-right">
                    {/* <button type="button" className="btn btn-sm shadow-none" onClick={history.push("/login")}>
                        <Icon icon={x} size={22} />
                    </button> */}
                    {/* {
                        props.user?.imageSrc ? 
                        <div className="h-25 d-flex">
                            <img className={props.user?.imageSrc ? "mx-3 float-left img-thumbnail w-25 h-25 rounded-circle" : "mx-3 w-25 h-25" } alt="..." src={props.user?.imageSrc}/>
                            <p className="small  text-primary text-wrap d-flex justify-content-center align-items-center">Welcome,   {props.user?.email ? props.user?.email : null }</p>
                        </div>
                        :
                        <div className="h-25 pt-2 text-center text-primary">Welcome,   {props.user?.email ? props.user?.email : null }</div>
                    } */}
                   
                    <div className="text-center pt-2">
                        <h5>Reset your password !</h5>
                    </div>
                </div>
            </div>
            <div className="card-body p-4">
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* New Password */}
                    <div className="form-group mb-4">
                        <p>{errors.newPassword ? <span className="text-danger">{errors.newPassword.message}</span> : 'New password'}</p>

                        <input
                            type="password"
                            className="form-control shadow-none"
                            placeholder="*******"
                            name="newPassword"
                            ref={register({
                                required: "new password is required",
                                minLength: {
                                    value: 8,
                                    message: "Minimun length 8 character"
                                }
                            })}
                            // {...register('newPassword', {
                            //     required: "new password is required",
                            //     minLength: {
                            //         value: 8,
                            //         message: "Minimun length 8 character"
                            //     }
                            // })}
                        />
                    </div>

                     {/* Confirm Password */}
                     <div className="form-group mb-4">
                        <p>{errors.confirmNewPassword ? <span className="text-danger">{errors.confirmNewPassword.message}</span> : 'Confirm New Password'}</p>

                        <input
                            type="password"
                            className="form-control shadow-none"
                            placeholder="*******"
                            name="confirmNewPassword"
                            ref={register({
                                required: "confirm password is required",
                                minLength: {
                                    value: 8,
                                    message: "Minimun length 8 character"
                                }
                            })}
                            // {...register('confirmNewPassword', {
                            //     required: "confirm password is required",
                            //     minLength: {
                            //         value: 8,
                            //         message: "Minimun length 8 character"
                            //     }
                            // })}
                        />
                    </div>
                    <button type="submit" className="btn btn-block shadow-none" disabled={isLoading}>
                        {isLoading ? <span>Saving...</span> : <span>Save</span>}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Forgot;