import React, { useState } from 'react'
import './style.scss'
import { VerifyCodeOnEmail } from "../../../utils/Password"
import { Icon } from 'react-icons-kit'
import Modal from 'react-bootstrap/Modal'
import { ic_clear } from 'react-icons-kit/md'
import { useForm } from 'react-hook-form'
import { toast, Slide } from "react-toastify"
toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
}) 
const Email = (props) => {
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)
    // Submit Form
    const onSubmit = async data => {
        try {
            setLoading(true)
            const response = await VerifyCodeOnEmail(data)
            if (response.status === 201) {
                setLoading(false)
                props.onSuccess()
                props.setData({name:"email", value: data.email})
                toast.success(response.data.messages)
            }
        } catch (error) {
            if (error) {
                setLoading(false)
                toast.error(error.response.data.messages)
            }
        }
    }

    return (
        <div>
            <Modal
                show={props.show}
                dialogClassName="custom-profile-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mb-0 mt-2">E-mail used in your biyeshadi account</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>


                        <div className="form-group mb-4">
                            {/* Email */}
                            {/* <div className="form-group mb-4"> */}
                                <p>{errors.email ? <span className="text-danger">{errors.email.message}</span> : 'Email'}</p>

                                <input
                                    type="email"
                                    name="email"
                                    className="form-control shadow-none"
                                    placeholder="example@example.com"
                                    ref={register({ required: "Email is required" })}
                                />
                            {/* </div> */}
                        </div>
                        <button type="submit" className="btn btn-block shadow-none" disabled={isLoading}>
                        {isLoading ? <span>Sending...</span> : <span>Send</span>}
                    </button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Email;