import React, { useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Requests } from '../../../utils/Requests'
import { dollarSign, send } from 'react-icons-kit/feather'
import LoadingComponent from '../../../components/loading/Index'
import { dateFormate } from '../../../utils/Helpers'
// import { useQuery } from 'react-query'


const Index = () => {
    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [packagesells, setPackageSell] = useState([])
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    useEffect(() => {

        const fetchData = async () => {
            const data = await Requests.getDashboard(header);
          

            if (data) {
                setData(data)
                setPackageSell(data.packageSell)
                setLoading(false)
            }
            setLoading(false)

        }
        fetchData();
        const PackageData = async () => {
            const data = await Requests.getPackageSellList(header);
            


            if (data) {
                // setPackageSell(data.invoices)
            }

        }
        PackageData();
    }, [header])
    // const {isError } = useQuery("data", PackageData, {
    //     retry: false,
    //     cacheTime: 600000
    // })
    return (
        <div className="dashboard-container">
            {isLoading ? <LoadingComponent /> :
                <div className="container-fluid">

                    {/* statistics container */}
                    <div className="row statistics-container">
                        <div className="col-12 col-padding">
                            <div className="card border-0 shadow-sm ">
                                <div className="card-header border-0 bg-white p-4">
                                    <h6>Statistics</h6>
                                </div>
                                <div className="card-body px-4 pb-4 px-lg-5 pb-lg-5 " >
                                    <div className="row">
                                        <div className="col-4 col-xl-3 col-xs-6">
                                            <div className="d-flex items">
                                                <div>
                                                    <Icon icon={dollarSign} size={22} className="icon-3" />
                                                </div>
                                                <div className="pl-3 pl-sm-4">
                                                    <h5>{data?.ghotok?.wallet?.currentBalance || ''} <small>tk</small></h5>
                                                    <p>wallet</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-xl-3 col-xs-6 ">
                                            <div className="d-flex items">
                                                <div>
                                                    <Icon icon={send} size={22} className="icon-4 leftIcon" />
                                                </div>
                                                <div className="pl-3 pl-sm-4">
                                                    <h5>{data?.ghotok?.wallet?.withdrawBalance || ''} <small>tk</small></h5>
                                                    <p>withdraw</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-7 col-xl-3">
                                            <div className="d-flex items md-margin ">
                                                {/* <div className="card border-0 shadow-sm mb-3"> className="card-header"*/}
                                                <div className="col-12 d-none d-md-block">
                                                    <h6>Comission</h6>
                                                    <p className="text-muted">{data.comission ? data.comission : '0'}% comission in every package</p>
                                                </div>
                                                <div className=" products-orders-status-container  ">
                                                    {/* Order status */}
                                                    <div className="col-12 d-none d-md-block ">
                                                        <div className="card border-0  mb-3 cardComission ">
                                                            <div className="card-body text-center px-0 pb-0 pt-0">
                                                                <div className="comission-circle rounded-circle">
                                                                    <div className="flex-center flex-column ">
                                                                        <h4 className="mb-0">{data.comission ? data.comission : '0'}%</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12  d-md-none" style={{ marginTop: "2px",marginLeft:"10px", width: "100%" }}>
                                    <h6>Comission</h6>
                                    <p className="text-muted" >{data.comission ? data.comission : '0'}% comission in every package</p>
                                </div>
                            </div>
                        </div>
                    </div>



                    
                    {/* Sell Package  */}
                    <div className="row products-orders-status-container">
                        <div className="col-12 col-xl-12 col-padding">
                            <div className="card border-0 shadow-sm">
                                <div className="card-header">
                                    <h6>Your Sell Packages</h6>
                                </div>
                                <div className="card-body page-links-container px-0 pt-2 pb-0 ">
                                    <table className="table table-sm table-borderless mb-0 ">
                                        <thead>
                                            <tr>
                                                <td className='text-center ' >SL</td>
                                                <td className='text-center '>Date</td>
                                                <td className='text-center' >UserID</td>
                                                <td className='text-center' >TransactionId</td>
                                                <td className='text-center' >Package</td>
                                                <td className='text-center ' >Comission</td>
                                                <td className='text-center ' >Percentage</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {packagesells.map((data, i) =>
                                                <tr key={i}>
                                                    <td className='text-center' >{i + 1}</td>
                                                    <td className='text-center ' >{dateFormate(data.createdAt)}</td>
                                                    <td className='text-center ' >{data.userId || ''}</td>
                                                    <td className='text-center' >{data.transactionId || ''}</td>
                                                    <td className='text-center' >{data.packageId?.title || ''} {data.amount || ''}<small>tk</small></td>
                                                    <td className='text-center' >{data.commission.amount }<small>tk</small></td>
                                                    <td className='text-center' >{data.commission.percentage}<small>%</small></td>
                                                </tr>

                                            )}
                                            <td></td>
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            }
        </div>
    );
}

export default Index;
