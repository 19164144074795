import React, { useState } from "react"
import Icon from "react-icons-kit"
import { useForm } from "react-hook-form"
import { x } from "react-icons-kit/feather"
import { VerifyOtp } from "../../../utils/Password"
import { toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, useHistory, useParams } from 'react-router-dom'
import './style.scss'
toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

const Otp = () => {
    const params = useParams()
    const { handleSubmit, register, formState: { errors } } = useForm()
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    const onSubmit = async data => {
        try {
            setLoading(true)
            let checkData = params.data
            let findData = checkData.split("@")
            
            if (findData.length === 1){
                data.phone = params.data
               
            }
            if ( findData.length === 2) {
                data.email = params.data
                
            }
            
            let obj = {
                code: data.code,
                phone: data.phone,
                email: data.email
            }
            const response = await VerifyOtp(obj)
            if (response.status === 201) {
                let email = response.data.userObj
                history.push(`/${email.email}/account`)
                toast.success(response.data.message)
                setTimeout(() => {
                    setLoading(false)

                }, 3000)

            }
        } catch (error) {
            if (error) {
                setLoading(false)

            }
        }
    }

    return (
        <div className="passwordCard">
            <div className="card-header bg-white border-0 px-2 py-3">
                <div className="text-right">
                    {/* <button type="button" className="btn btn-sm shadow-none" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button> */}

                    <div className="text-center pt-2">
                        <h5>Enter 4 digit verification code
                            <Link
                                to="/login"
                                type="button"
                                className="btn shadow-none"
                            >
                                <Icon style={{ marginTop: '-12px' }} icon={x} size={20} />
                            </Link>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="card-body p-4">
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* Phone */}
                    <div className="form-group mb-4">
                        <p>{errors.code ? <span className="text-danger">{errors.code.message}</span> : 'Verification Code'}</p>

                        <input
                            type="number"
                            name="code"
                            className="form-control shadow-none"
                            ref={register({ required: "4 digit code is required" })}
                        // {...register('code', {
                        //     required: "4 digit code is required"
                        // })}
                        />
                    </div>

                    <button type="submit" className="btn btn-block shadow-none" disabled={isLoading}
                    // onClick ={history.push("/forget/password")}
                    >
                        {isLoading ? <span>verifying...</span> : <span>verify</span>}
                    </button>

                </form>
            </div>
        </div>
    );
};

export default Otp;