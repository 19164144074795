import React, {  useState } from 'react'
import './style.scss'
import { Icon } from 'react-icons-kit'
import Modal from 'react-bootstrap/Modal'
import { ic_clear } from 'react-icons-kit/md'
import { smartphone, mail } from "react-icons-kit/feather"
import Phone from './phone'
import Email from './email'

const ForgotOption = (props) => {
    const [showEmail, setShowEmail] = useState(false)
    const [showPhone, setShowPhone] = useState(false)
    // const [show, setShow] = useState(false)

    return (
        <div>
            <Modal
                show={props.show}
                dialogClassName="custom-profile-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mb-0 mt-2">Choose your verification option</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div className="card-body d-flex justify-content-center p-4">
                        <button
                            className="btn mx-5 shadow-none"
                            onClick={() =>setShowEmail(true)}
                        >
                            <Icon icon={mail} size={60} />
                            <span>Email</span>
                        </button>

                        <button
                            className="btn mx-5 shadow-none"
                            onClick={() =>setShowPhone(true)}
                        >
                            <Icon icon={smartphone} size={60} />
                            <span>Phone</span>
                        </button>
                    </div>

                </Modal.Body>
            </Modal>

            {showEmail ?
                <Email
                showPhone={showPhone}
                    // data={ghotok}
                    // loading={isUpdate}
                    // update={handleUpdate}
                    onHide={() => setShowEmail(false)}
                />
                : null}

            {showPhone ?
                <Phone
                showPhone={showPhone}
                    // data={ghotok}
                    // loading={isUpdate}
                    // update={handleUpdate}
                    onHide={() => setShowPhone(false)}
                />
                : null}
        </div>
    );
}

export default ForgotOption;