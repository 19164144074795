
import React, { useCallback, useEffect, useState } from 'react'
import './style.scss'
// import Icon from 'react-icons-kit'
import { Images } from '../../../utils/Images'
// import { chevronLeft, chevronRight } from 'react-icons-kit/feather'
import { Requests } from '../../../utils/Requests'
import { dateFormate } from '../../../utils/Helpers'

import LoadingComponent from '../../../components/loading/Index'

const Index = () => {
    const [wallet, setWallet] = useState({})
    const [isLoading, setLoading] = useState(true)
    const [transactions, setTransactions] = useState([])
    // const [notifications, setNotifications] = useState([])
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    const fetchData = useCallback(async () => {
        const data = await Requests.getWallet(header)
        if (data) {
            setWallet(data.wallet)
            setTransactions(data.transactions)
            // setNotifications(data.notifications)
            setLoading(false)
        }
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])


    if (isLoading) return <LoadingComponent />

    return (
        <div className="wallet-index">
            <div className="container-fluid">

                {/* Wallet balances */}
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card balance-card border-0 shadow-sm">
                            <div className="card-body p-3 p-sm-4">
                                <div className="row">
                                    <div className="col-6 col-sm-4 text-center p-0 mb-4 mb-sm-0">
                                        <div className="icon-container-1 flex-center flex-column">
                                            <img src={Images.DollarAdd} alt="..." />
                                        </div>
                                        <h6>{wallet.currentBalance} tk</h6>
                                        <p>Current Balance</p>
                                    </div>
                                    <div className="col-6 col-sm-4 text-center p-0 mb-4 mb-sm-0">
                                        <div className="icon-container-2 flex-center flex-column">
                                            <img src={Images.DollarPending} alt="..." />
                                        </div>
                                        <h6>{wallet.pendingBalance} tk</h6>
                                        <p>Pending Balance</p>
                                    </div>

                                    <div className="col-6 col-sm-4 text-center p-0">
                                        <div className="icon-container-3 flex-center flex-column">
                                            <img src={Images.DollarSuccess} alt="..." />
                                        </div>
                                        <h6>{wallet.withdrawBalance} tk</h6>
                                        <p>Withdraw</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row products-orders-status-container">

                    {/* Products of this month */}
                    <div className="col-12 col-xl-12 col-padding">
                        <div className="card border-0 shadow-sm">
                            <div className="card-header" style={{ backgroundColor: '#fff' }}>
                                <h6>Your Transactions</h6>
                                {/* <p className="text-muted">Overview of Latest Month</p> */}
                            </div>
                            <div>
                                <table className="table table-sm table-borderless mb-0">
                                    <thead>
                                        <tr className="card-header" style={{ backgroundColor: '#fff', height:45 }}>
                                            {/* <td className="pl-2 pl-lg-3">SL</td> */}
                                            {/* <td className="pl-2"></td> */}
                                            <td style={{ width: 10 }}></td>
                                            <td style={{ width: 60 }}>SL</td>
                                            <td style={{ width: 80 }}>Date</td>
                                            <td style={{ width: 80 }}>Received Moblie No</td>
                                            <td style={{ width: 80 }}>Amount</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactions.map((data, i) =>
                                            <tr className="card-header" style={{ backgroundColor: '#fff', height:45 }} key={i+1}>
                                                <td style={{ width: 10 }}></td>
                                                <td style={{ width: 60 }}>{i++}</td>
                                                <td style={{ width: 80 }}>{dateFormate(data.createdAt)}</td>
                                                <td style={{ width: 80 }}>{data.accountNumber}</td>
                                                <td style={{ width: 80 }}>{data.amount}tk</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                {/* Load more & load less button */}
                                {/* {slice < data && data.products.length ?
                <div className="text-center py-3">
                    <button
                        type="button"
                        className="btn shadow-sm border load-more-btn"
                        onClick={() => setLice(data.products.length)}
                    >Load More</button>
                </div>
                :
                <div className="text-center py-3">
                    <button
                        type="button"
                        className="btn shadow-sm border load-more-btn"
                        onClick={() => setLice(5)}
                    >Load Less</button>
                </div>
            } */}
                            </div>
                        </div>
                    </div>

                </div>

            </div>


        </div>
    );

}

export default Index;