import React, { useState } from 'react'
import LoadingComponent from '../../../components/loading/Index'



const Index = () => {
    const [isLoading] = useState(false)
    

   
    
    return (
        <div className="user-container">
            {isLoading ? <LoadingComponent /> :
                <div className="container-fluid">
                    <div className="row products-orders-status-container">
                        <div className="col-12 col-xl-12 col-padding">
                            
                        </div>



                    </div>
                </div>
            }
        </div>
    );
}

export default Index;
