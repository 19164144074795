import React, { useState } from 'react'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import './style.scss'
import Icon from 'react-icons-kit'
import { download, eye, eyeOff } from 'react-icons-kit/feather'
const StepOne = () => {
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoding] = useState(false)
    const [gender, setGender] = useState(null);
    const [verificationMethod, setVerificationMethod] = useState(null);
    const [mobileNumberFor, setMobileNumberFor] = useState('');
    const [profileImage, setProfileImage] = useState({
        value: null,
        preview: null,
        error: null,
    });
    const [NIDImage, setNIDImage] = useState({
        value: null,
        preview: null,
        error: null,
    });
    const [anyBillCopy, setAnyBillCopyImage] = useState({
        value: null,
        preview: null,
        error: null,
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setConfirmPasswordShown(!confirmPasswordShown);
  };
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [referenceWay, setReferenceWay] = useState('');
    const [mobileBankingNumber, setMobileBankingNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [NIDNo, setNIDNo] = useState('');
    const [errorMessage, setErrorMessage] = useState("");

    const thumbnailHandeller = (event) => {
        let file = event.target.files[0];
        if (file) {
            setProfileImage({
                value: file,
                preview: URL.createObjectURL(file),
                error: null,
            });
        }
    };
    const thumbnailNIDHandeller = (event) => {
        let file = event.target.files[0];
        if (file) {
            setNIDImage({
                value: file,
                preview: URL.createObjectURL(file),
                error: null,
            });
        }
    };
    const thumbnailanyBillCopyHandeller = (event) => {
        let file = event.target.files[0];
        if (file) {
            setAnyBillCopyImage({
                value: file,
                preview: URL.createObjectURL(file),
                error: null,
            });
        }
    };
    const onSubmit = async (data) => {
        if (!profileImage.value)
            return setProfileImage({
                ...profileImage,
                error: "Thumbnail is required.",
            });
        if (!NIDImage.value)
            return setNIDImage({
                ...NIDImage,
                error: "Thumbnail is required.",
            });
        if (!anyBillCopy.value)
            return setAnyBillCopyImage({
                ...anyBillCopy,
                error: "Thumbnail is required.",
            });
        try {
            let formData = new FormData();
            formData.append("name", name);
            formData.append("fbPageUrl", data.fbPageUrl);
            formData.append("email", email);
            formData.append("whatsappOrImo", data.whatsappOrImo);
            formData.append("phone", phone);
            formData.append("gender", gender);
            formData.append("verificationMethod", verificationMethod);
            formData.append("mobileNumberFor", mobileNumberFor);
            formData.append("address", address);
            formData.append("referenceWay", referenceWay);
            formData.append("mobileBankingNumber", mobileBankingNumber);
            formData.append("password", password);
            formData.append("profileImage", profileImage.value);
            formData.append("NIDImage", NIDImage.value);
            formData.append("anyBillCopy", anyBillCopy.value);
            formData.append("NIDNo", NIDNo);



            if (password !== confirmPassword) {
                setErrorMessage("Those passwords didn’t match. Try again.");
            } else {
                setLoding(true)
                const response = await Axios.post(`${api}ghotok/register`, formData)
                if (response.status === 201) {
                    setLoding(false)
                    localStorage.setItem('token', response.data.token)
                    history.push(`/register/phone-verified`)
                    toast.success(response.data.message)
                }

            }

        } catch (error) {
            if (error) {
                setLoding(false)
                toast.error(error.response ? error.response.data.message : null)
            }
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    {/* Name */}
                    <div className="col-12 col-md-6">
                        <div className="form-group mb-4">
                            {errors.name && errors.name.message ? (
                                <p className="text-danger">{errors.name && errors.name.message}</p>
                            ) : <p>Name</p>
                            }

                            <input
                                type="text"
                                name="name"
                                className="form-control shadow-none"
                                placeholder="Enter your name"
                                ref={register({ required: "Name is required" })}
                                // value={
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* Facebook Id */}
                    <div className="col-12 col-md-6">
                        <div className="form-group mb-4">

                            <p>Facebook ID</p>
                            <input
                                type="text"
                                name="fbPageUrl"
                                className="form-control shadow-none"
                                placeholder="Enter your Facebook ID"
                                ref={register({})}
                            />
                        </div>
                    </div>
                    {/* E-mail */}
                    <div className="col-12 ">
                        <div className="form-group mb-4">
                            {errors.email && errors.email.message ? (
                                <p className="text-danger">{errors.email && errors.email.message}</p>
                            ) : <p>E-mail</p>
                            }

                            <input
                                type="text"
                                name="email"
                                className="form-control shadow-none"
                                placeholder="Enter your E-mail"
                                ref={register({
                                    required: "E-mail is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    {/* Whatsapp or Imo */}
                    <div className="col-12 col-md-6">
                        <div className="form-group mb-4">
                            {errors.whatsappOrImo && errors.whatsappOrImo.message ? (
                                <p className="text-danger">{errors.whatsappOrImo && errors.whatsappOrImo.message}</p>
                            ) : <p>WhatsApp Or Imo</p>
                            }

                            <input
                                type="text"
                                name="whatsappOrImo"
                                className="form-control shadow-none"
                                placeholder="Enter your WhatsApp Or Imo Number"
                                ref={register({ required: "whatsapp Or Imo is required" })}

                            />
                        </div>
                    </div>
                    {/* Phone */}
                    <div className="col-12 col-md-6">
                        <div className="form-group mb-4">
                            {errors.phone && errors.phone.message ? (
                                <p className="text-danger">{errors.phone && errors.phone.message}</p>
                            ) : <p>Mobile number</p>
                            }

                            <input
                                type="text"
                                name="phone"
                                className="form-control shadow-none"
                                placeholder="Enter your Mobile number"
                                ref={register({
                                    required: "Mobile number is required",
                                    pattern: {
                                        value: /^(?:\+88|88)?(01[3-9]\d{8})$/,
                                        message: "Invalid phone number"
                                    }
                                })}
                                onChange={(e) => setPhone(e.target.value)}

                            />
                        </div>
                    </div>
                    {/* Address */}
                    <div className="col-12 ">
                        <div className="form-group mb-4">
                            {errors.address && errors.address.message ? (
                                <p className="text-danger">{errors.address && errors.address.message}</p>
                            ) : <p>Address</p>
                            }

                            <input
                                type="text"
                                name="address"
                                className="form-control shadow-none"
                                placeholder="Enter your Address"
                                ref={register({ required: "Address is required" })}
                                onChange={(e) => setAddress(e.target.value)}

                            />
                        </div>
                    </div>
                    {/* Gender */}
                    <div className=" col-12 col-md-6" >
                        <div className="form-group md-4">
                            {errors.gender && errors.gender.message ? (
                                <p className="text-danger">{errors.gender && errors.gender.message}</p>
                            ) : <p>Gender</p>
                            }

                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="inlineRadio1"
                                    value="Male"
                                    ref={register({ required: "select gender is required" })}
                                    onChange={() => setGender("Male")}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="inlineRadio2"
                                    value="Female"
                                    ref={register({ required: "select gender is required" })}
                                    onChange={() => setGender("Female")}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                    <div className=" col-12 col-md-6" >
                        <div className="form-group mb-4">
                            {/* <div className="form-group mb-4 col-md-6"> */}
                            {/* {errors.referenceWay && errors.referenceWay.message ? (
                                <p className="text-danger">{errors.referenceWay && errors.referenceWay.message}</p>
                            ) : <p>Reference Way</p>
                            } */}
                            <p>Reference Way</p>
                            <input
                                type="text"
                                name="referenceWay"
                                className="form-control shadow-none"
                                placeholder="Enter Reference"
                                onChange={(e) => setReferenceWay(e.target.value)}
                                ref={register({})}
                            // required: "Reference Way is required" 
                            />
                        </div>

                    </div>
                    {/* Mobile Banking Informations */}
                    <div className=" col-12 col-md-6" >
                        <div className="form-group mb-4">
                            {errors.mobileBankingNumber && errors.mobileBankingNumber.message ? (
                                <p className="text-danger">{errors.mobileBankingNumber && errors.mobileBankingNumber.message}</p>
                            ) : <p>Mobile Banking Informations</p>
                            }
                            <input
                                type="text"
                                name="mobileBankingNumber"
                                className="form-control shadow-none"
                                placeholder="Enter Mobile Banking Number"
                                ref={register({ required: " Mobile Banking Number is required" })}
                                onChange={(e) => setMobileBankingNumber(e.target.value)}
                            />
                        </div>

                    </div>
                    {/* MobileNumberFor */}
                    <div className=" col-12 col-md-6 " >
                        <div className="form-group md-4">
                            {errors.mobileNumberFor && errors.mobileNumberFor.message ? (
                                <p className="text-danger">{errors.mobileNumberFor && errors.mobileNumberFor.message}</p>
                            ) : <div className='d-none d-md-block'><br /> </div>
                            }


                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio" name="mobileNumberFor"
                                    id="inlineRadio3" value="Bkash"
                                    onChange={() => setMobileNumberFor("Bkash")}
                                    ref={register({ required: " select Mobile Banking Number is" })} />
                                <label className="form-check-label" htmlFor="inlineRadio3">Bkash</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio" name="mobileNumberFor"
                                    id="inlineRadio4" value="Nagad"
                                    onChange={() => setMobileNumberFor("Nagad")}
                                    ref={register({ required: " select Mobile Banking Number is" })} />
                                <label className="form-check-label" htmlFor="inlineRadio4">Nagad</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio" name="mobileNumberFor" id="inlineRadio5"
                                    value="Rocket"
                                    onChange={() => setMobileNumberFor("Rocket")}
                                    ref={register({ required: " select Mobile Banking Number is" })} />
                                <label className="form-check-label" htmlFor="inlineRadio5">Rocket</label>
                            </div>
                        </div>
                    </div>
                    {/* Password */}
                    <div className="col-12 col-md-6 form-group mb-4">
                        {errors.password && errors.password.message ? (
                            <p className="text-danger">{errors.password && errors.password.message}</p>
                        ) : <p>Password</p>
                        }
                    <div className='input-group'>
                    <input
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            className="form-control shadow-none"
                            placeholder="Enter password"
                            ref={register({
                                required: "Please enter password",
                                minLength: {
                                    value: 8,
                                    message: "Minimun length 8 character"
                                }
                            })}
                            onChange={(e) => setPassword(e.target.value)}
                            
                        />
                        {passwordShown ? <Icon icon={eye} style={{padding:"5px", backgroundColor:"#E9E8E8", borderTopRightRadius:"4px", borderBottomRightRadius:"4px"}} onClick ={()=>togglePassword()}/>:<Icon icon={eyeOff} style={{padding:"5px", backgroundColor:"#E9E8E8", borderTopRightRadius:"4px", borderBottomRightRadius:"4px"}} onClick ={()=>togglePassword()}/>}
                        
                    </div>
                    </div>
                    {/* RePassword */}
                    <div className="col-12 col-md-6 form-group mb-4">
                        {errors.confirmPassword && errors.confirmPassword.message ? (
                            <p className="text-danger">{errors.confirmPassword && errors.confirmPassword.message}</p>
                        ) : <p>Confirm Password</p>
                        }
                        <div className='input-group'>
                        <input
                            type={confirmPasswordShown ? "text" : "password"}
                            name="confirmPassword"
                            className="form-control shadow-none"
                            placeholder="Enter your password again"
                            ref={register({
                                required: "Please enter confirm password",
                                minLength: {
                                    value: 8,
                                    message: "Minimun length 8 character"
                                }
                            })}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {confirmPasswordShown ? <Icon type= "button" style={{padding:"5px", backgroundColor:"#E9E8E8", borderTopRightRadius:"4px", borderBottomRightRadius:"4px"}} icon={eye} onClick ={()=>toggleConfirmPassword()}/>:<Icon icon={eyeOff} style={{padding:"5px", backgroundColor:"#E9E8E8", borderTopRightRadius:"4px", borderBottomRightRadius:"4px"}} onClick ={()=>toggleConfirmPassword()}/>}

                        </div>
                        
                    </div>
                    <div className="col-12 col-md-12 form-group mb-4" style={{marginTop:"-19px"}}><span style={{ color: "red", fontSize:"14px", }}>{errorMessage}</span></div>
                    <div className="col-12 col-md-6">
                        <div className="form-group mb-4">
                        {errors.verificationMethod && errors.verificationMethod.message ? (
                                <p className="text-danger">{errors.verificationMethod && errors.verificationMethod.message}</p>
                            ) : <p>Verification method</p>
                            }
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio"
                                    name="verificationMethod"
                                    id="inlineRadio6"
                                    value="phone"
                                    ref={register({ required: "Verification method is required" })}
                                    onChange={() => setVerificationMethod("phone")}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio6">Phone</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="radio"
                                    name="verificationMethod"
                                    id="inlineRadio7"
                                    value="email"
                                    ref={register({ required: "Verification method is required" })}
                                    onChange={() => setVerificationMethod("email")}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio7">Email</label>
                            </div>
                        </div>
                    </div>
                    

                    {/* NID No */}
                    <div className="col-12  col-md-6">
                        <div className="form-group mb-4">
                            {errors.NIDNo && errors.NIDNo.message ? (
                                <p className="text-danger">{errors.NIDNo && errors.NIDNo.message}</p>
                            ) : <p>NID Number</p>
                            }

                            <input
                                type="text"
                                name="NIDNo"
                                className="form-control shadow-none"
                                placeholder="Enter your NID Number"
                                ref={register({ required: "NID Number is required" })}
                                onChange={(e) => setNIDNo(e.target.value)}

                            />
                        </div>
                    </div>
                    {/* profile image */}
                    <div className="col-12 col-md-12 form-group mb-4">
                        {errors.profileImage && errors.profileImage.message ? (
                            <p className="text-danger">{errors.profileImage && errors.profileImage.message}</p>
                        ) : null
                        }
                        <div className="thumbnail-container">
                            <div className='d-flex'>
                                {profileImage.preview ? (
                                    <div className="thumbnail-container text-center" style={{ height: '5px' }}>
                                        <div className="image border">
                                            <img
                                                src={profileImage.preview}
                                                className="img-fluid"
                                                alt="..."
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                <div className="image border">
                                    <input
                                        type="file"
                                        id="formFile"
                                        name='profileImage'
                                        accept=".jpg, .png, .jpeg"
                                        className="upload form-control"
                                        onChange={thumbnailHandeller}
                                        ref={register({ required: "profile image is required" })}
                                    // onChange={(e) => setProfileImage(e.target.files[0])}
                                    />
                                    <div className="flex-column form-control" style={{ borderRadius: '5px' }}>
                                        <div className="flex-left" style={{ borderRadius: '5px' }}>
                                            <p>Profile Image<Icon icon={download} style={{ float: 'right' }} size={18} /></p>
                                        </div>
                                        <div className="flex-right">

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* NID Photo */}
                    <div className="col-12 col-md-12 form-group mb-4">
                        {errors.NIDImage && errors.NIDImage.message ? (
                            <p className="text-danger">{errors.NIDImage && errors.NIDImage.message}</p>
                        ) : null
                        }
                        <div className="thumbnail-container">
                            <div className='d-flex'>
                                {NIDImage.preview ? (
                                    <div className="thumbnail-container text-center" style={{ height: '5px' }}>
                                        <div className="image border">
                                            <img
                                                src={NIDImage.preview}
                                                className="img-fluid"
                                                alt="..."
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                <div className="image border">
                                    <input
                                        type="file"
                                        name='NIDImage'
                                        accept=".jpg, .png, .jpeg"
                                        className="upload form-control"
                                        // onChange={(e) => setNIDImage(e.target.files[0])}
                                        onChange={thumbnailNIDHandeller}
                                        ref={register({ required: "NID Image is required" })}
                                    />
                                    <div className="flex-column form-control" style={{ borderRadius: '5px' }}>
                                        <div className="flex-left" style={{ borderRadius: '5px' }}>
                                            <p>NID Photo  <Icon icon={download} style={{ float: 'right' }} size={18} /></p>
                                        </div>
                                        <div className="flex-right">
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        {/* </div> */}
                    </div>

                    {/* Any Bill Copy */}
                    <div className="col-12 col-md-12 form-group mb-4">
                        {errors.anyBillCopy && errors.anyBillCopy.message ? (
                            <p className="text-danger">{errors.anyBillCopy && errors.anyBillCopy.message}</p>
                        ) : null
                        }
                        <div className="thumbnail-container">
                            <div className='d-flex'>
                                {anyBillCopy.preview ? (
                                    <div className="thumbnail-container text-center" style={{ height: '5px' }}>
                                        <div className="image border">
                                            <img
                                                src={anyBillCopy.preview}
                                                className="img-fluid"
                                                alt="..."
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                <div className="image border">
                                    <input
                                        type="file"
                                        name='anyBillCopy'
                                        accept=".jpg, .png, .jpeg"
                                        className="upload form-control"
                                        // onChange={(e) => setAnyBillCopyImage(e.target.files[0])}
                                        onChange={thumbnailanyBillCopyHandeller}
                                        ref={register({ required: "anyBillCopy image is required" })}

                                    />
                                    <div className="flex-column form-control" style={{ borderRadius: '5px' }}>
                                        <div className="flex-left" style={{ borderRadius: '5px' }}>
                                            <p>Any Bill Copy  <Icon icon={download} style={{ float: 'right' }} size={18} /></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                   
                    <div className="col-12 " style={{ marginBottom: '100px' }}>
                        <button type="submit" className=" btn shadow-none" style={{ margin: 0, width: "100%", background: "#CF2969", borderColor: "#CF2969" }} disabled={isLoading}>
                            {isLoading ? <span>Submitting...</span> : <span>Submit</span>}
                        </button>

                    </div>
                </div>

            </form>
            {/* <SendOTP
                    data = {}
            /> */}
        </div>
    );
}

export default StepOne;