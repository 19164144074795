import React from 'react'
import './App.scss'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import LandingPage from './pages/home/landingpage'
import TermsanndConditions from './pages/termsanndconditions/index'
import Login from './pages/auth/Login'
import ResetPassword from './pages/auth/option/forgot'
import ChooseOption from './pages/auth/option/option'

import RegisterMaster from './pages/auth/RegisterMaster'
import Email from './pages/auth/option/email'
import Phone from './pages/auth/option/phone'
import Otp from './pages/auth/option/otp'

import Master from './pages/admin/master/Index'
import FourOFour from './pages/fourOfour/Index'

import ScrollToTop from './components/scrollToTop/Index'
import PrivateRoute from './components/privateRoute/Index'
function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Switch>
            
            <Route exact path="/reset-email" component={Email} />
            <Route exact path="/reset-phone" component={Phone} />
            <Route exact path="/reset" component={ChooseOption} />
            <Route exact path="/:data/otp" component={Otp} />
            <Route exact path="/:email/account" component={ResetPassword} />
            <Route  path="/register" component={RegisterMaster} />
             <Route exact path="/" component={LandingPage} />
             <Route exact path="/termsandconditions" component={TermsanndConditions} />
             <Route exact path="/login" component={Login} />
            <PrivateRoute path="/dashboard" role="ghotok">
              <Master/>
            </PrivateRoute>
            <Route path="*" component={FourOFour} />
          </Switch>
        </ScrollToTop> 
      </Router>
    </div>
  );
}

export default App;
