import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { api } from '../../../utils/api'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'


const StepThree = () => {
    const history = useHistory()
    const { register, handleSubmit, errors } = useForm()
    const [isLoading, setLoading] = useState(false)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    useEffect(() => {
        const user = localStorage.getItem('tempUser')
        const tempUser = JSON.parse(user)
        if (!tempUser) {
            return history.push('/dashboard/')
        }

    }, [history, header])

    const handleResendOtp = async () => {
        try {
            
            const response = await Axios.get(`${api}ghotok/resendotp`, header)
            if (response.status === 201) {
                toast.success(response.data.message)
            }
            
        } catch (error) {
            if (error) {

            }
        }
    }
    // Submit Form
    const onSubmit = async (data) => {
        try {
            setLoading(true)
            let otp = { otp: data.otp }
            const response = await Axios.put(`${api}ghotok/otp`, otp, header)
            if (response.status === 200) {
                history.push('/dashboard/')
                setLoading(false)
                toast.success(response.data.message)
                
            }
            setLoading(false)
        } catch (error) {
            if (error) {
                setLoading(false)
                toast.error(error.response ? error.response.data.message : null)
            }
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>


                {/* Address */}
                <div className="form-group mb-4 ">
                    {errors.otp && errors.otp.message ? (
                        <p className="text-danger">{errors.otp && errors.otp.message}</p>
                    ) : <p style={{textTransform: "none"}}>Check your phone or email for OTP</p>
                    }

                    <input
                        type="text"
                        name="otp"
                        className="form-control shadow-none"
                        placeholder="Enter otp"
                        ref={register({ required: "otp is required" })}
                    />
                </div>

                <div className="d-flex justify-content-between">
                    <div type="button"  className="btn shadow-none" >
                         <span  onClick={() => handleResendOtp()}>Resend Otp</span>
                    </div>
                    <button type="submit" className="btn shadow-none" disabled={isLoading}>
                        {isLoading ? <span>Submitting...</span> : <span>Submit</span>}
                    </button>
                </div>
            </form>


        </div>
    );
}

export default StepThree;