
import Logo from '../assets/static/logo.png'
import FourOFour from '../assets/static/four_o_four.png'
import PackageSticker from '../assets/static/package.jpg'
import Empty from '../assets/static/empty.png'
import Avatar from '../assets/static/avatar.jpg'
import DollarAdd from '../assets/static/dollar-plus.png'
import DollarPending from '../assets/static/dollar-pending.png'
import DollarSuccess from '../assets/static/dollar-success.png'
import MaskGroup4 from '../assets/image/MaskGroup4.png'
import MaskGroup1 from '../assets/image/MaskGroup1.png'
import MaskGroup5 from '../assets/image/MaskGroup5.png'
import hundred from '../assets/image/hundred.png'
import material from '../assets/image/Iconmaterialpermdatasetting.png'
import materialMoney from '../assets/image/Icon material-money-off.png'
import TraUser from '../assets/image/Tra-user.png'
import Agent from "../assets/image/logo agent.png"
import AgentCover from "../assets/image/web.jpg"
export const Images = {
    Logo,
    FourOFour,
    Empty,
    Avatar,
    PackageSticker,
    DollarAdd,
    DollarPending,
    DollarSuccess,
    MaskGroup1,
    MaskGroup4,
    MaskGroup5,
    hundred,
    material,
    materialMoney,
    TraUser,
    Agent,
    AgentCover,
}
