import React, { useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { Images } from '../../utils/Images'
import { menu } from 'react-icons-kit/feather'
import { Link, useHistory } from 'react-router-dom'
import { user, logOut } from 'react-icons-kit/feather'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Requests } from '../../utils/Requests'
const Index = ({ toggle, }) => {
    const history = useHistory()
    const [agentStatus, setAgentStatus] = useState({})
    const [show, setShow] = useState(true)
    const [loggingOut, setLoggingOut] = useState(false)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    useEffect(() => {

        const AgentStatusData = async () => {
            const data = await Requests.AgentStatus(header);
            if (data.status === "Approved") {
                setShow(false)
            }
            if (data) {
                setAgentStatus(data)
            }

        }
        AgentStatusData();
    }, [header])
    // Logout
    const doLogout = () => {
        setLoggingOut(true)
        setTimeout(() => {
            setLoggingOut(false)
            localStorage.clear()
            history.push('/')
        }, 2000)
    }
//
    return (
        <div className="custom-navbar">
            <div className="d-flex">
                <div>
                    <ul>
                        <li className="d-lg-none">
                            <button type="button" className="btn btn-sm shadow-none" onClick={toggle}>
                              <Icon icon={menu} size={20} />
                            </button>
                        </li>
                    </ul>
                </div>
                
                {show ? (
                    <div className='text-left' style={{width:"70%"}}><p>Your Account <span style ={{color:"red" }}>{agentStatus.status}</span> Please contact support +8801317801712</p></div>
                ):null}
                <div className='ml-auto'>
                     <ul>
                        <li>
                            <DropdownButton
                                title={<img src={Images.Avatar} className="img-fluid rounded-circle" alt="..." />}>
                                <Dropdown.Item as={Link} to="/dashboard/profile">
                                    <Icon icon={user} size={18} className="icon" />
                                    <span>Profile</span>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={doLogout} disabled={loggingOut}>
                                    <Icon icon={logOut} size={18} className="icon" />
                                    <span>{loggingOut ? 'Logging out...' : 'Logout'}</span>
                                </Dropdown.Item>
                            </DropdownButton>
                        </li>
                    </ul>
                   
                    
                </div>
            </div>
        </div>
    );
}

export default Index;