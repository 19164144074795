import React, { useState } from "react"
import Icon from "react-icons-kit"
import { useForm } from "react-hook-form"
import { x } from "react-icons-kit/feather"
import { VerifyCodeOnEmail } from "../../../utils/Password"
import { toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, useHistory } from 'react-router-dom'
import './style.scss'
toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

const Email = (props) => {
    const { handleSubmit, register, formState: { errors } } = useForm()
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()

    const onSubmit = async data => {
        try {
            setLoading(true)
            const response = await VerifyCodeOnEmail(data)
            
            if (response.status === 201) {
                history.push(`/${data.email}/otp`)   
                setLoading(false)
                props.onSuccess()
                props.setData({name:"email", value: data.email})
                toast.success(response.data.messages)
            }
        } catch (error) {
            if (error) {
                setLoading(false)
                // toast.error(error.response.data.messages)
            }
        }
    }

    return (
        <div className="passwordCard">
            <div className="card-header bg-white border-0 px-2 py-3">
                <div className="text-right">
                    {/* <button type="button" className="btn btn-sm shadow-none" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button> */}

                    <div className="text-center pt-2">
                        <h5>E-mail used in your biyeshadi account
                        <Link
                                    to="/login"
                                    type="button"
                                    className="btn shadow-none"
                                >
                                    <Icon style={{  marginTop: '-12px' }} icon={x} size={20} />
                                </Link>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="card-body p-4">
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* Email */}
                    <div className="form-group mb-4">
                        <p>{errors.email ? <span className="text-danger">{errors.email.message}</span> : 'E-mail'}</p>

                        <input
                            type="email"
                            name="email"
                            className="form-control shadow-none"
                            placeholder="example@example.com"
                            ref={register({
                                required: "E-mail is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                        />
                    </div>

                    <button type="submit" className="btn btn-block shadow-none" disabled={isLoading}>
                        {isLoading ? <span>Sending...</span> : <span>Send</span>}
                    </button>

                </form>
            </div>
        </div>
    );
};

export default Email;