import React, { useState } from "react"
import Icon from "react-icons-kit"
import { useForm } from "react-hook-form"
import { x } from "react-icons-kit/feather"
import { VerifyPhone } from "../../../utils/Password"
import { toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, useHistory } from 'react-router-dom'
import './style.scss'

toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
})

const Forgot = (props) => {
    const { handleSubmit, register, formState: { errors } } = useForm()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const onSubmit = async data => {
        try {

            setLoading(true)
            const response = await VerifyPhone(data)
            if (response.status === 201) {
                history.push(`/${data.phone}/otp`)
                setLoading(false)
                props.onSuccess()
                props.setData({ name: "phone", value: data.phone })
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error) {
                setLoading(false)
            }
        }
    }

    return (
        <div className="passwordCard">
            <div className="card-header bg-white border-0 px-2 py-3">
                <div >

                    <div className="text-center pt-2">
                        <h5>Phone number used in your account
                            <Link
                                to="/login"
                                type="button"
                                className="btn shadow-none"
                            >
                                <Icon style={{ marginTop: '-12px' }} icon={x} size={20} />
                            </Link>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="card-body p-4">
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* Phone */}
                    <div className="form-group mb-4">
                        <p>{errors.phone ? <span className="text-danger">{errors.phone.message}</span> : 'Phone'}</p>

                        <input
                            type="text"
                            name="phone"
                            className="form-control shadow-none"
                            placeholder="01*********"
                            ref={register({
                                required: "phone number is required",
                                pattern: {
                                    value: /^(?:\+88|88)?(01[3-9]\d{8})$/,
                                    message: "Invalid phone number"
                                }
                            })}
                        />
                    </div>

                    <button type="submit" className="btn btn-block shadow-none" disabled={isLoading}>
                        {isLoading ? <span>Sending...</span> : <span>Send</span>}
                    </button>

                </form>
            </div>
        </div>
    );
};

export default Forgot;