import Axios from 'axios'
import { api } from './api'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({ autoClose: 2000 })
// send otp to phone
export async function SendOtpToPhone(header) {
    const response = await Axios.get(`${api}ghotok/account/sendotptophone`, header)
    return response.data
}

// send otp to to email
export async function SendOtpToEmail(header) {
    const response = await Axios.get(`${api}ghotok/account/sendotptoemail`, header)
    return response.data
}

//Phone number for sending  otp code
export async function VerifyPhone(data) {
    const response = await Axios.put(`${api}ghotok/verifyphone`, data)
    return response
}

//Phone number for sending  otp code
export async function VerifyCodeOnEmail(data) {
    const response = await Axios.put(`${api}ghotok/emailverificationcode`, data)
    return response
}

//verify otp code send to your number
export async function VerifyOtp(data) {
    const response = await Axios.put(`${api}ghotok/verifyotp`, data)
    return response
}

//reset password
export async function ResetPassword(data) {
    const response = await Axios.put(`${api}ghotok/resetpassword`, data)
    return response
}