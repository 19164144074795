import Axios from 'axios'
import { api } from './api'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({ autoClose: 2000 })

// Error handeller
const errorHandeller = error => {
    if (error && error.message === 'unauthorized request') {
        toast.error(error.message + ' Logging out...')

        setTimeout(() => {
            localStorage.clear()
            window.location.reload()
        }, 2000)
    } else if (error && error.message === 'Token expired') {
        toast.error(error.message + ' Logging out...')

        setTimeout(() => {
            localStorage.clear()
            window.location.reload()
        }, 2000)
    } else {
        toast.error(error.message)
    }
}

// Get Profile
const getProfile = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/profile`, header)
        if (response.status === 200) {
            return response.data.profile    
        }

    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}
// Get Profile
const AgentStatus = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/agentstatus`, header)
        if (response.status === 200) {
            return response.data.AgentStatus    
        }

    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}
// ShortIndex
const ShortIndex = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/users`, header)
        if (response.status === 200) {
            return response.data.UserList    
        }

    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}
// Get Package
const UserList = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/userlist`, header)
       
        if (response.status === 200) {
            return response.data.UserList
        }
    } catch (error) {
        if (error && error.response) {

            return errorHandeller(error.response.data)
        }
    }
}
// Update Profile
const updateProfile = async (data, header) => {
    try {
        const response = await Axios.put(`${api}ghotok/profile`, data, header)
        if (response.status === 201) {
            toast.success(response.data.message)
            return true
        }
    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}

// Get Dashboard
const getDashboard = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/dashboard`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}
const getPackageSellList = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/packagesell`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}

// Get Package
const getPackages = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/package`, header)
       
        if (response.status === 200) {
            return response.data.packages
        }
    } catch (error) {
        if (error && error.response) {

            return errorHandeller(error.response.data)
        }
    }
}
const SslRequest= async (data, header) =>{
    try {
        const response = await Axios.post(`${api}ghotok/payment/ssl-request`, data, header)
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        if (error) return errorHandeller(error)
    }
}




// Register
const Register = async (data, header) => {
    try {
        const response = await Axios.post(`${api}ghotok/createuser`, data, header)
        if (response.status === 201) {
            return response.data.message
        }
    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}
// Create order
const createOrder = async (data, header) => {
    try {
        const response = await Axios.post(`${api}ghotok/user`, data, header)
        if (response.status === 201) {
            return response.data.message
        }
    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}

// Get Wallet
const getWallet = async (header) => {
    try {
        const response = await Axios.get(`${api}ghotok/wallet`, header)
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}

// Withdraw Request
const withdrawRequest = async (data, header) => {
    try {
        const response = await Axios.post(`${api}ghotok/withdraw`, data, header)
        if (response.status === 201) {
            return response.data.message
        }
    } catch (error) {
        if (error && error.response) {
            return errorHandeller(error.response.data)
        }
    }
}

const resetPassword = async (data) =>{
    try {

        const response = await Axios.put(`${api}/verifyphone`,data)
        if(response.status === 201){
            return response.data.message
        }
        
    } catch (error) {
        if(error && error.response){
            return errorHandeller(error.response.data)
        }
    }
}

export const Requests = {
    getProfile,
    updateProfile,
    getDashboard,
    getPackageSellList,
    getPackages,
    SslRequest,
    createOrder,
    getWallet,
    withdrawRequest,
    resetPassword,
    Register,
    ShortIndex,
    UserList,
    AgentStatus
}