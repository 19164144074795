import React, { useEffect, useState } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
// import { Images } from '../../../utils/Images'
import { ic_pin_drop } from 'react-icons-kit/md'
import { phone, mail, edit2, user, image, facebook, command, cpu, folder } from 'react-icons-kit/feather'

import { u1F3E7 } from 'react-icons-kit/noto_emoji_regular'


import { Requests } from '../../../utils/Requests'

import LoadingComponent from '../../../components/loading/Index'
import ProfileEditModal from '../../../components/modal/Profile'

const Index = ({ ghotok }) => {
    const [show, setShow] = useState(false)
    const [veiw, setVeiw] = useState(false)
    const [isUpdate, setUpdate] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
   
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000);
    }, [])

    // Handle update 
    const handleUpdate = async data => {
        setUpdate(true)
        await Requests.updateProfile(data, header)
        setUpdate(false)
        setShow(false)
        window.location.reload()
    }

    const [state, setState] = useState({ isOpen: false });
    const [stateNID, setStateNID] = useState({ isOpen: false });
    const [stateAnyBillCopy, setStateAnyBillCopy] = useState({ isOpen: false });

    const handleShowDialog = () => {
        setState({ isOpen: !state.isOpen });
    };

    const handleNID = () => {
        setStateNID({ isOpen: !stateNID.isOpen });
    };

    const handleAnyBillCopy = () => {
        setStateAnyBillCopy({ isOpen: !stateAnyBillCopy.isOpen });
    };

    if (isLoading) return <LoadingComponent />

    return (
        <div className="profile-index">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-padding">
                        <div className="card profile-card border-0 shadow-sm">
                            <div className="card-header bg-white">
                                <div className="flex-center flex-column">

                                    <div className="profile-image-container rounded-circle">
                                        <img src={ghotok ? ghotok.profileImage : null} className="img-fluid" onClick={handleShowDialog} alt="..." />
                                        {state.isOpen && (
                                            <div
                                                className="dialog"
                                                open
                                                onClick={handleShowDialog}
                                            >
                                                <img
                                                    className="image"
                                                    src={ghotok.profileImage}
                                                    onClick={handleShowDialog}
                                                    alt="..."
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* Name */}
                                    <h5>{ghotok ? ghotok.name : null}</h5>

                                </div>
                            </div>
                            <div className="card-body p-4">

                                <table className="table table-sm table-borderless">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={user} size={18} className="icon" /></td>
                                            <td style={{ width: 140 }}><p>Id</p></td>
                                            <td><p>: {ghotok.ghotokMediaId ? ghotok.ghotokMediaId : "Ghotok"}</p></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={phone} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Phone</p></td>
                                            <td><p>: {ghotok ? ghotok.phone : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={mail} size={18} /></td>
                                            <td style={{ width: 140 }}><p>E-mail</p></td>
                                            <td><p>: {ghotok ? ghotok.email : null}</p></td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={user} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Gender</p></td>
                                            <td><p>: {ghotok && ghotok.gender ? ghotok.gender : null}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={facebook} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Facebook Id</p></td>
                                            <td><p>: {ghotok && ghotok.fbPageUrl ? ghotok.fbPageUrl : null}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={ic_pin_drop} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Address</p></td>
                                            <td><p>: {ghotok && ghotok.address ? ghotok.address : null}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={phone} size={18} /></td>
                                            <td style={{ width: 140 }}><p>whatsapp / Imo</p></td>
                                            <td><p>: {ghotok && ghotok.whatsappOrImo ? ghotok.whatsappOrImo : null}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={command} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Reference Way</p></td>
                                            <td><p>: {ghotok && ghotok.referenceWay ? ghotok.referenceWay : null}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={u1F3E7} size={18} /></td>
                                            <td style={{ width: 140 }}><p>{ghotok && ghotok.mobileNumberFor ? ghotok.mobileNumberFor : null} Number</p></td>
                                            <td><p>: {ghotok && ghotok.mobileBankingNumber ? ghotok.mobileBankingNumber : null}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={cpu} size={18} /></td>
                                            <td style={{ width: 140 }}><p>NID No</p></td>
                                            <td><p>: {ghotok && ghotok.NIDNo ? ghotok.NIDNo : null}</p></td>
                                        </tr>

                                        <tr>
                                            <td style={{ width: 20 }}><Icon icon={folder} size={18} /></td>
                                            <td style={{ width: 140 }}><p>Document</p></td>
                                            {veiw ? (<td><p>:<button onClick={() => setVeiw(false)} style={{ border: 'none', backgroundColor: "#fff", padding: '2px' }}>Hide</button></p></td>) : (<td><p>:<button onClick={() => setVeiw(true)} style={{ border: 'none', backgroundColor: "#fff", padding: '2px' }}>View</button></p></td>)}
                                        </tr>
                                        {veiw ? (

                                            <tr>
                                                <td style={{ width: 20 }}><Icon icon={image} size={18} /></td>
                                                <td style={{ width: 140 }}><p>NID Photo</p></td>
                                                <td><p><img src={ghotok ? ghotok.NIDImage : null} onClick={handleNID} style={{ width: 100, height: 100 }} alt="..."></img></p>
                                                    {stateNID.isOpen && (
                                                        <div
                                                            style={{ marginTop: 20 }}
                                                            className="dialog"
                                                            open
                                                            onClick={handleNID}
                                                        >
                                                            <img
                                                                className="image"
                                                                src={ghotok.NIDImage}
                                                                onClick={handleNID}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>


                                        ) : null}

                                        {veiw ? (


                                            <tr>
                                                <td style={{ width: 20 }}><Icon icon={image} size={18} /></td>
                                                <td style={{ width: 140 }}><p>Any Bill Copy</p></td>
                                                <td><p><img src={ghotok ? ghotok.anyBillCopy : null} onClick={handleAnyBillCopy} style={{ width: 100, height: 100 }} alt="..."></img></p>
                                                {stateAnyBillCopy.isOpen && (
                                                        <div 
                                                            style={{ marginTop: 20 }}
                                                            className="dialog"
                                                            open
                                                            onClick={handleAnyBillCopy}
                                                        >
                                                            <img
                                                                className="image"
                                                                src={ghotok.anyBillCopy}
                                                                onClick={handleAnyBillCopy}
                                                                alt="..."
                                                            />
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>

                                        ) : null}

                                    </tbody>
                                </table>

                                <button type="button" className="btn shadow rounded-circle" onClick={() => setShow(true)}>
                                    <Icon icon={edit2} size={20} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {ghotok && show ?
                <ProfileEditModal
                    show={show}
                    data={ghotok}
                    loading={isUpdate}
                    update={handleUpdate}
                    onHide={() => setShow(false)}
                />
                : null}
        </div>
    );
}

export default Index;