import React, { useState, useEffect } from 'react'
import './style.scss'
import { Switch, Route } from 'react-router-dom'
import { Requests } from '../../../utils/Requests'

import Layout from '../../../components/layout/Index'
import DashboardIndex from '../dashboard/Index'
import UserList from '../userList/Index'
import WalletIndex from '../wallet/Index'
import ProfileIndex from '../profile/Index'
import FourOFour from '../fourOfour/Index'
import LoadingComponent from '../../../components/loading/Index'
import Package from '../Package/Index'
import CreateUser from '../createUser/UserRegister'
import StepThreePage from '../../auth/Register/StepThree'
import notApproved from '../../admin/notApproved/index'

const Index = () => {
    const [isLoading, setLoading] = useState(true)
    const [ghotok, setGhotok] = useState({})
    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })

    useEffect(() => {
        const fetchData = async () => {
            const data = await Requests.getProfile(header)
            if (data) {
                setGhotok(data)
                setLoading(false)
            }
        }
        fetchData()
    }, [header])

    return (
        <div className="master">

            {/* If loading to get loggedin user */}
            {isLoading ? <LoadingComponent /> : null}

            <Layout ghotok={ghotok}/>
            <div className="main">
                <Switch>
                    <Route exact path="/register/phone-verified" component={StepThreePage} />
                    <Route exact path="/dashboard/" component={DashboardIndex} />
                    <Route exact path="/dashboard/status-change/" component={notApproved} />
                    <Route exact path="/dashboard/package/" component={Package} />
                    <Route exact path="/dashboard/wallet" component={WalletIndex} />
                    <Route exact path="/dashboard/createuser" component={CreateUser} />
                    <Route exact path="/dashboard/user/" component={UserList} />
                    <Route exact path="/dashboard/profile">
                        <ProfileIndex ghotok={ghotok} />
                    </Route>
                    
                    <Route path="*" component={FourOFour} />
                </Switch>
            </div>
        </div>
    );
}

export default Index;

