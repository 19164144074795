import React, { useState } from 'react'
import './style.scss'
import { VerifyPhone } from "../../../utils/Password"
import { Icon } from 'react-icons-kit'
import Modal from 'react-bootstrap/Modal'
import { ic_clear } from 'react-icons-kit/md'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast, Slide } from "react-toastify"
toast.configure({
    autoClose: 1500,
    transition: Slide,
    position: "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
}) 
const Phone = (props) => {
    const { handleSubmit, register, formState: { errors } } = useForm()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const onSubmit = async data => {
        try {

            setLoading(true)
            const response = await VerifyPhone(data)
            if (response.status === 201) {
                history.push(`/${data.phone}/otp`)
                setLoading(false)
                props.onSuccess()
                props.setData({ name: "phone", value: data.phone })
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error) {
                setLoading(false)
            }
        }
    }


    return (
        <div>
            <Modal
                show={props.show}
                dialogClassName="custom-profile-modal"
            >
                <Modal.Header>
                    <div className="d-flex w-100">
                        <div><h6 className="mb-0 mt-2">Phone used in your  biyeshadi agent account</h6></div>
                        <div className="ml-auto">
                            <button
                                type="button"
                                className="btn btn-sm btn-light shadow-none rounded-circle p-1"
                                onClick={props.onHide}
                            >
                                <Icon icon={ic_clear} size={25} />
                            </button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form onSubmit={handleSubmit(onSubmit)}>


                    {/* Phone */}
                    <div className="form-group mb-4">
                        <p>{errors.phone ? <span className="text-danger">{errors.phone.message}</span> : 'Phone'}</p>

                        <input
                            type="text"
                            name="phone"
                            className="form-control shadow-none"
                            placeholder="01*********"
                            ref={register({
                                required: "phone number is required",
                                pattern: {
                                    value: /^(?:\+88|88)?(01[3-9]\d{8})$/,
                                    message: "Invalid phone number"
                                }
                            })}
                        />
                    </div>
                        <button type="submit" className="btn btn-block shadow-none" disabled={isLoading}>
                        {isLoading ? <span>Sending...</span> : <span>Send</span>}
                    </button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Phone;