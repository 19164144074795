import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { Images } from "../../utils/Images";
import { ic_person } from 'react-icons-kit/md'
import Icon from 'react-icons-kit'

const Index = () => {
    const history = useHistory();
    return (
        <div>
            <div className="custom-navbar-agent shadow-sm">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="navSpace">
                                {/* <div className='navSize'> */}
                                <div className="pt-1 pt-lg-0 d-flex justify-content-between navSize">
                                   <div><img src={Images.Agent} className="siteLogo" alt="Logo"  onClick={() => history.push(`/`)} /></div> 
                                    <div className="page-links dropbtn">
                                        <Dropdown>
                                            <Dropdown.Toggle className='dropbtn'>
                                                Help
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item >Email: <p className='lower'>support@biyesadi.com</p></Dropdown.Item>
                                                <Dropdown.Item>Mobile: <p className='lower'>+8801317801712</p></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                                {/* Page links */}
                                <div className="d-none d-md-block">
                                    <div className="page-links">
                                        <button type="button" className="shadow-none menuButton" onClick={() => history.push(`/login`)}>Login</button>
                                    </div>
                                </div>
                                <div className="d-md-none">
                                    <button type="button" className="btn shadow-none menu-bar-btn" style={{ padding: "26px 0" }} onClick={() => history.push(`/login`)}>
                                        <Icon icon={ic_person} size={25} />
                                    </button>
                                </div>
                                {/* </div> */}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="custom-navbar-break"></div>
        </div>
    );
}

export default Index;