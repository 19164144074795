import React, { useEffect, useState, useCallback } from 'react'
import './style.scss'

import { Icon } from 'react-icons-kit'
import { check, plusCircle,  arrowLeftCircle } from 'react-icons-kit/feather'
import { Images } from '../../../utils/Images'
import { Requests } from '../../../utils/Requests'
import { Link } from 'react-router-dom'
import LoadingComponent from "../../../components/loading/Index"
import SingleSelect from './Single'
import { toast } from 'react-toastify'
const Index = () => {
    const [isLoading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [user, setUser] = useState({ value: null, options: [], error: null })
    const [packageId, setPackageId] = useState({ value: null, options: [], error: null })

    const handleUser = event => setUser({ ...user, value: event, error: null })
    const handlePackage = event=> setPackageId({ ...packageId, value: event.target.value , error: null })

    const [header] = useState({
        headers: { Authorization: "Bearer " + localStorage.getItem('token') }
    })
    const sslRequest = async () => {
        let userId = user.value.value;
        let _id = packageId.value
        let obj ={
            user:userId,
            package:_id
        }
        
        setLoading(true)
        const res = await Requests.SslRequest(obj, header)
        // setLoading(true)
        if (res.link) {

            window.location.replace(`${res.link}`)
        }
        else {
            toast.error(res.message, {
                autoClose: 4000
            })
        }
    }

    // Fetch data
    const fetchData = useCallback( async () => {
        const usersResult = await Requests.ShortIndex(header)
        if (usersResult) setUser(
            // ...user,
            user => ({
                options: usersResult && usersResult.map(item => ({
                    label: item.name + ' - ' + item.email + ' - ' + item.phone + ' - ' + item.profileId,
                    value: item._id
                }))
            })
            
            
        )
        const packageResult = await Requests.getPackages(header)
        if (packageResult) setPackageId(
            // {
            // ...packageId,
            packageId => ({
            options: packageResult && packageResult.map(item => ({
                label: item.title + ' - ' + item.type,
                value: item._id,
                currency: item.discountPercentage ? item.finalCurrency : item.currency,
            }))

            
        })
        // }
        )
        if (packageResult) {
            setItems(packageResult)
            setLoading(false)
        }
    }, [header])

    useEffect(() => {
        fetchData()
    }, [header, fetchData])


    return (
        <div className="dashboard-container">
            {isLoading ? <LoadingComponent /> :
                <div className="container-fluid">

                    <div className="row statistics-container">
                        <div className="col-12 col-padding">
                            <div className="card border-0 shadow-sm">
                                <div className="card-header border-0 bg-white p-4">
                                    <div className="d-flex">
                                        <div><h6 className="mb-0">Create Order</h6></div>
                                        <div className="ml-auto rounded-circle">
                                            <Link
                                                to="/dashboard"
                                                type="button"
                                                className="btn shadow-none"
                                            >
                                                <Icon style={{ color: '#CF2969', marginLeft: '-10px', marginTop: '0px' }} icon={arrowLeftCircle} size={22} />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row statistics-container">
                        <div className="col-12 col-padding">
                            <div className="card border-0 shadow-sm">
                                <div className="card-header border-0 bg-white p-4">
                                    <div className="row align-items-start">
                                        <div className="col-12 col-md-5">
                                            <div><h6>Find user</h6><br /></div>
                                            <SingleSelect className="form-control form-control-lg"
                                                style={{ borderRadius: 25, fontSize: '15px' }}
                                                placeholder="user"
                                                options={user.options}
                                                error={user.error}
                                                value={handleUser}
                                            />
                                            {/* <input className="form-control form-control-lg" style={{ borderRadius: 25, fontSize: '15px' }} type="text" placeholder="Find by ID, E-mail, Phone" aria-label=".form-control-lg example" /> */}
                                        </div>
                                        <div className="col">
                                            <div style={{ float: 'right' }}><h6>Create user
                                                <Link
                                                    to="/dashboard/createuser"
                                                    type="button"
                                                    className="btn shadow-none"
                                                >
                                                    <Icon icon={plusCircle} style={{ color: '#CF2969' }} size={18} className="icon round" />
                                                </Link>
                                            </h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        {items.map((item, i) => {
                                            return (
                                                <div className="col-12 col-md-4 " style={{ marginBottom: 30}} key={i}>
                                                    <div className="card package-card ">
                                                        <div className=" shadow-sm" >

                                                            <div className="card-body" key={i + 1}>
                                                                <div className="round">

                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id={item.title}
                                                                        error={packageId.error}
                                                                        value={item._id}
                                                                    onChange={(e)=> handlePackage(e)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={item.title}
                                                                    />
                                                                </div>
                                                                <div className="image-container rounded-circle">
                                                                    <img src={item.image || Images.PackageSticker} className="img-fluid" alt="..." />
                                                                </div>
                                                                <br />
                                                                <div className="pricing mb-3">
                                                                    <h5>{item.title}</h5>
                                                                    <h6>{item.type}</h6> {/* Package type */}
                                                                    <h3>{item.discountPercentage}% off</h3> {/* Discaount percentage*/}
                                                                    <h5 className={item.discountPercentage === 0 ? "" : "linecross"}>{item.currency} {item.currencyType}</h5> {/* Package price */}
                                                                    <h4 className={item.discountPercentage === 0 ? "hidden" : ""}>{item.finalCurrency} {item.currencyType}</h4> {/* Package price */}
                                                                    <p>For {item.duration} {item.durationType}</p> {/* Package duration */}
                                                                </div>
                                                                <ul>
                                                                    {/* <li><p><Icon icon={check} size={20} className="text-success mr-2" />Proposal limit: 10 person (daily)</p></li> */}
                                                                    <li><p><Icon icon={check} size={20} className="text-success mr-2" />Total proposal limit: {item.proposal.totalLimit} person</p></li>
                                                                    <li><p><Icon icon={check} size={20} className="text-success mr-2" />Contact view limit: {item.proposal.contactViewLimit}</p></li>
                                                                    <li><p><Icon icon={check} size={20} className="text-success mr-2" />Unlimited Messaging</p></li>
                                                                </ul>
                                                                <div className="watermark" />

                                                            </div>
                                                        </div>

                                                    </div>
                                                    {/* {show ? <PurchaseModal show={show} onHide={() => setShow(false)} /> : null} */}
                                                </div>

                                            )
                                        })}


                                    </div>

                                </div>
                                <div className="col-12 d-grid gap-2" style={{ paddingBottom: '25px' }}>
                                    <button className="btn btn-primary"
                                        style={{ width: "100%", background: "#CF2969", borderColor: "#CF2969" }}
                                        type="button"
                                        onClick={sslRequest}
                                        disabled={isLoading}>{isLoading ? <span>Submitting...</span> : <span>Submit</span>}</button>
                                        {/* <button type="submit" className="btn shadow-none" disabled={isLoading}> */}
                                    
                                {/* </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        // </div>

    );
};

export default Index;